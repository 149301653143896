import React, { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import Submissions from './Submissions';

const LoginRoute: React.FC = ({}): ReactElement => {
    return (
        <Switch>
            <Route path="/submissions/:id" component={Submissions} />
            <Route path="/submissions" component={Submissions} />
        </Switch>
    );
};

export default LoginRoute;
