import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, List, Row, Select, Skeleton } from 'antd';
import { FollowupRequest, FollowupRequestStore } from '../../../store/modules/followupRequest';
import { StoreStore } from '../../../store/modules/store';
import { FollowupRequestComment, FollowupRequestCommentStore } from '../../../store/modules/followupRequestComments';
import { UserProfile, UserProfileStore } from '../../../store/modules/userProfile';
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
import { FollowupRequestStatus, FollowupRequestStatusStore } from '../../../store/modules/followupRequestStatus';
import { FormStore } from '../../../store/modules/form';
import { FormSubmissionStore } from '../../../store/modules/formSubmission';
import FormSubmissionDrawer from '../../submissions/components/FormSubmissionDrawer';
import { CRMStore, followupRequest, followupRequestComment, followupRequestStatus } from '../../../store/modules';
import { followupRequestDrawer } from '../../../store/modules/app';
import { connect } from 'react-redux';
import { nullToString } from '../../../utilities';

interface MappedStateProps {
    forms: FormStore['byId'];
    formSubmissions: FormSubmissionStore['byId'];
    followupRequestStatuses: FollowupRequestStatusStore['byId'];
    followupRequests: FollowupRequestStore['byId'];
    followupRequestComments: FollowupRequestCommentStore['byId'];
    stores: StoreStore['byId'];
    userProfiles: UserProfileStore['byId'];
    currentUser: UserProfile | null;
}

interface MappedDispatchProps {
    postComment: typeof followupRequestComment.post;
    patchFollowupRequest: typeof followupRequest.patch;
    getFollowupRequests: typeof followupRequest.get;
    getFollowupRequestStatuses: typeof followupRequestStatus.get;
}

interface OwnProps {
    visible: boolean;
    followupRequestId: number | null;
    onClose: () => void;
}

const mapStateToProps = (state: CRMStore): MappedStateProps => ({
    forms: state.form.byId,
    formSubmissions: state.formSubmission.byId,
    followupRequestStatuses: state.followupRequestStatus.byId,
    followupRequests: state.followupRequest.byId,
    followupRequestComments: state.followupRequestComment.byId,
    stores: state.store.byId,
    userProfiles: state.userProfile.byId,
    currentUser: state.app.app.session.currentUser,
});

const mapDispatchToProps: MappedDispatchProps = {
    postComment: followupRequestComment.post,
    patchFollowupRequest: followupRequest.patch,
    getFollowupRequests: followupRequest.get,
    getFollowupRequestStatuses: followupRequestStatus.get,
};

type Props = MappedStateProps & MappedDispatchProps & OwnProps;

const FollowupRequestDrawer: React.FC<Props & FormComponentProps> = ({
    forms,
    formSubmissions,
    followupRequestStatuses,
    followupRequests,
    followupRequestComments,
    stores,
    userProfiles,
    visible,
    followupRequestId,
    form,
    currentUser,
    postComment,
    patchFollowupRequest,
    getFollowupRequests,
    getFollowupRequestStatuses,
    onClose,
}): React.ReactElement => {
    const [showingFormSubmission, setShowingFormSubmission] = useState<boolean>(false);
    useEffect(() => {
        if (visible) {
            getFollowupRequestStatuses();
            getFollowupRequests(followupRequestId, {
                include: [{ formSubmission: 'form' }, { followupRequestComments: 'userProfile' }],
            });
        }
    }, [visible, followupRequestId]);
    let followupRequest: FollowupRequest | null = null;
    let comments: FollowupRequestComment[] | [] = [];
    if (followupRequestId != null && followupRequests[followupRequestId] != null) {
        followupRequest = followupRequests[followupRequestId];
    }
    if (followupRequest != null) {
        comments = Object.values(followupRequestComments)
            //@ts-ignore
            .filter((v: FollowupRequestComment) => v.followupRequestId === followupRequest.id)
            .sort((a, b) => a.id - b.id);
    }
    const getStore = (): string => {
        if (
            followupRequest &&
            followupRequest.storeId != null &&
            // @ts-ignore
            stores[followupRequest.storeId] != null
        ) {
            // @ts-ignore
            return stores[followupRequest.storeId].name;
        }
        return '';
    };
    interface DescriptionItemProps {
        title: string;
        content: any;
    }
    const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
        <div
            style={{
                fontSize: 14,
                lineHeight: '22px',
                marginBottom: 7,
                color: 'rgba(0,0,0,0.65)',
            }}
        >
            <p
                style={{
                    marginRight: 8,
                    display: 'inline-block',
                    color: 'rgba(0,0,0,0.85)',
                }}
            >
                {title}:
            </p>
            {nullToString(content)}
        </div>
    );
    const handleNewComment = (e: FormEvent) => {
        e.preventDefault();
        form.validateFieldsAndScroll((error, values) => {
            if (!error && currentUser != null && followupRequest != null) {
                postComment({
                    ...values,
                    followupRequestId: followupRequest.id,
                    userProfileId: currentUser.id,
                    dateCreated: moment(),
                });
                form.resetFields();
            }
        });
    };
    const handleSetStatus = (value: number) => {
        if (followupRequest != null) {
            patchFollowupRequest(followupRequest.id, {
                followupRequestStatusId: value,
            });
        }
    };
    const handleViewFormSubmission = () => {
        setShowingFormSubmission(true);
    };
    const getFormName = () => {
        if (followupRequest != null) {
            try {
                return forms[formSubmissions[followupRequest.formSubmissionId].formId].name;
            } catch (e) {
                return 'Unknown Form';
            }
        } else {
            return 'Unknown Form';
        }
    };
    return (
        <div>
            <Drawer
                closable={false}
                visible={visible}
                onClose={onClose}
                width={640}
                title={
                    <Skeleton loading={followupRequest == null} paragraph={false}>
                        {followupRequest != null && (
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'inline-block' }}>
                                    <h2 style={{ marginBottom: 4 }}>Followup Request #{followupRequest.id}</h2>
                                    <h4 style={{ marginBottom: 0 }}>{getFormName()}</h4>
                                </div>
                                <Select
                                    value={followupRequest.followupRequestStatusId}
                                    size="small"
                                    style={{
                                        minWidth: 100,
                                        marginRight: 16,
                                        alignSelf: 'flex-start',
                                        marginTop: 8,
                                    }}
                                    onChange={handleSetStatus}
                                >
                                    {Object.values(followupRequestStatuses).map((value: FollowupRequestStatus) => (
                                        <Select.Option key={value.id} value={value.id}>
                                            {value.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </Skeleton>
                }
            >
                {followupRequest != null && (
                    <FormSubmissionDrawer
                        formSubmissionId={followupRequest.formSubmissionId}
                        visible={showingFormSubmission}
                        onClose={() => setShowingFormSubmission(false)}
                    />
                )}
                <Skeleton paragraph={{ rows: 15 }} title={false} loading={followupRequest == null}>
                    {followupRequest != null && (
                        <div>
                            <h3>Request Information</h3>
                            <Row>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="First Name"
                                        content={nullToString(followupRequest.requesterFirstName)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Phone Number"
                                        content={nullToString(followupRequest.requesterPhoneNumber)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Last Name"
                                        content={nullToString(followupRequest.requesterLastName)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Email Address"
                                        content={nullToString(followupRequest.requesterEmailAddress)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <DescriptionItem title="Store" content={getStore()} />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Submitted"
                                        content={moment(followupRequest.dateCreated).format('MM/DD/YYYY - hh:mma')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Form Submission"
                                        content={
                                            <a
                                                href=""
                                                onClick={e => {
                                                    e.preventDefault();
                                                    handleViewFormSubmission();
                                                }}
                                            >
                                                View
                                            </a>
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Updated"
                                        content={
                                            followupRequest.dateModified != null &&
                                            moment(followupRequest.dateModified).format('MM/DD/YYYY - hh:mma')
                                        }
                                    />
                                </Col>
                            </Row>
                            <h3>Comments</h3>
                            <List
                                itemLayout="vertical"
                                dataSource={comments}
                                renderItem={(item: FollowupRequestComment) => (
                                    <List.Item className="comment-bubble">
                                        <List.Item.Meta
                                            title={
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {userProfiles[item.userProfileId] != null && (
                                                        <span>
                                                            {`${userProfiles[item.userProfileId].firstName} ${
                                                                userProfiles[item.userProfileId].lastName
                                                            }`}
                                                        </span>
                                                    )}
                                                    <span>
                                                        {moment(item.dateCreated).format('MM/DD/YYYY - hh:mma')}
                                                    </span>
                                                </div>
                                            }
                                        />
                                        {item.comment}
                                    </List.Item>
                                )}
                            />
                            <Form onSubmit={handleNewComment}>
                                <Row style={{ marginLeft: '-8px', marginRight: '-8px', marginTop: 5 }}>
                                    <Col span={20}>
                                        <Form.Item style={{ marginTop: 1 }}>
                                            {form.getFieldDecorator('comment', {
                                                rules: [{ required: true, message: 'Please enter a comment.' }],
                                            })(
                                                <Input
                                                    placeholder="Enter comment."
                                                    style={{
                                                        borderTopRightRadius: 0,
                                                        borderBottomRightRadius: 0,
                                                        borderRight: 'none',
                                                    }}
                                                />,
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item>
                                            <Button
                                                style={{
                                                    marginTop: 5,
                                                    width: '100%',
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 0,
                                                }}
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    )}
                </Skeleton>
            </Drawer>
        </div>
    );
};

export default connect<MappedStateProps, MappedDispatchProps, OwnProps, CRMStore>(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()<any>(FollowupRequestDrawer));
