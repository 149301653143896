import {
    actionCreator,
    patchRequestAction,
    postRequestAction,
    deleteRequestAction,
    getRequestAction,
} from '../reduxTools';
import { IterableObject, RequestableStoreBase, RequestResponse } from '../../types';
import { abort, error, success } from 'redux-observable-requests';
import { combineReducers } from 'redux';
import { Moment } from 'moment';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { mapTo } from 'rxjs/operators';
import { postNotification } from './app/app';
import { Observable } from 'rxjs';
import { userProfile } from '../../schemas';
import { GET as FORM_SUBMISSIONS_GET } from './formSubmission';

//-----------------------------------------------------
// Types
//-----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserProfileStore extends RequestableStoreBase<UserProfile> {}

export interface UserProfile {
    id?: number;
    realm?: string;
    username: string;
    password?: string;
    email: string;
    emailVerified?: boolean;
    verificationToken?: string;
    userStatusId: number;
    entityId?: number;
    departmentId?: number;
    dateLastActive?: string | Moment;
    dateLastModified?: string | Moment;
    dateCreated?: string | Moment;
    lastName: string;
    firstName: string;
}

export const GET = 'userProfile/GET';
export const PATCH = 'userProfile/PATCH';
export const POST = 'userProfile/POST';
export const PURGE = 'userProfile/PURGE';
export const CLEAR_LOCAL_STATE = 'userProfile/CLEAR_LOCAL_STATE';
export const LOGIN = 'userProfile/LOGIN';
export const LOGOUT = 'userProfile/LOGOUT';
export const CHANGE_PASSWORD = 'userProfile/CHANGE_PASSWORD';

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const get = getRequestAction(GET, 'user-profiles', null, null, userProfile);
export const patch = patchRequestAction<UserProfile>(PATCH, 'user-profiles');
export const post = postRequestAction<UserProfile>(POST, 'user-profiles');
export const purge = deleteRequestAction(PURGE, 'user-profiles');
export const clearLocalState = actionCreator(CLEAR_LOCAL_STATE);
export const login = postRequestAction<{ username: string; password: string }>(
    LOGIN,
    'user-profiles',
    null,
    'login?include=user',
);
export const logout = postRequestAction<{}>(LOGOUT, 'user-profiles', null, 'logout');
export const changePassword = postRequestAction<{ oldPassword: string; newPassword: string }>(
    CHANGE_PASSWORD,
    'user-profiles',
    null,
    'change-password',
);

//-----------------------------------------------------
// Epics
//-----------------------------------------------------

const onPasswordChangeSuccess: Epic = (action$): Observable<any> =>
    action$.pipe(
        ofType(success(CHANGE_PASSWORD)),
        mapTo(
            postNotification({
                type: 'success',
                message: 'Password changed.',
                details: '',
            }),
        ),
    );

const onPasswordChangeError: Epic = (action$): Observable<any> =>
    action$.pipe(
        ofType(error(CHANGE_PASSWORD)),
        mapTo(
            postNotification({
                type: 'error',
                message: 'Incorrect password provided.',
                details: '',
            }),
        ),
    );

export const epic = combineEpics(onPasswordChangeSuccess, onPasswordChangeError);

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const byId = (state: UserProfileStore['byId'] = {}, action: RequestResponse<any>): UserProfileStore['byId'] => {
    switch (action.type) {
        case CLEAR_LOCAL_STATE:
            return {};
        case success(GET):
        case success(FORM_SUBMISSIONS_GET):
            if (action.payload.response.userProfiles != null) {
                return { ...state, ...action.payload.response.userProfiles };
            }
            return state;
        case success(POST):
        case success(PATCH):
            return { ...state, ...{ [action.payload.response.id]: action.payload.response } };
        case success(PURGE):
            let newState: IterableObject<UserProfile> = { ...state };
            delete newState[action.meta.id];
            return newState;
        default:
            return state;
    }
};

const getLoading = (
    state: UserProfileStore['getLoading'] = false,
    action: RequestResponse<any>,
): UserProfileStore['getLoading'] => {
    switch (action.type) {
        case GET:
            return true;
        case abort(GET):
        case error(GET):
        case success(GET):
            return false;
        default:
            return state;
    }
};

const postLoading = (
    state: UserProfileStore['postLoading'] = false,
    action: RequestResponse<any>,
): UserProfileStore['postLoading'] => {
    switch (action.type) {
        case POST:
            return true;
        case abort(POST):
        case error(POST):
        case success(POST):
            return false;
        default:
            return state;
    }
};

const patchLoading = (
    state: UserProfileStore['patchLoading'] = false,
    action: RequestResponse<any>,
): UserProfileStore['patchLoading'] => {
    switch (action.type) {
        case PATCH:
            return true;
        case abort(PATCH):
        case error(PATCH):
        case success(PATCH):
            return false;
        default:
            return state;
    }
};

const purgeLoading = (
    state: UserProfileStore['purgeLoading'] = false,
    action: RequestResponse<any>,
): UserProfileStore['purgeLoading'] => {
    switch (action.type) {
        case PURGE:
            return true;
        case abort(PURGE):
        case error(PURGE):
        case success(PURGE):
            return false;
        default:
            return state;
    }
};

export const reducer = combineReducers<UserProfileStore>({
    byId,
    getLoading,
    postLoading,
    patchLoading,
    purgeLoading,
});
