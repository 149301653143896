import React, { useEffect, useState } from 'react';
import { CRMStore, form, formSubmission, store } from '../../store/modules';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import { FormStore } from '../../store/modules/form';
import { Select, DatePicker, Row, Col, Spin, Empty } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { FormSubmission, FormSubmissionStore } from '../../store/modules/formSubmission';
import SubmissionStatisticsHeading from './components/SubmissionStatisticsHeading';
import StorePerformanceTable from './components/StorePerformanceTable';
import { FormFieldStore } from '../../store/modules/formField';
import { FormFieldValue, FormFieldValueStore } from '../../store/modules/formFieldValue';
import { StoreStore } from '../../store/modules/store';
import { createdWithinDateRange, withMatchingParent } from '../../store/selectors';

interface MappedDispatchProps {
    getSubmissions: typeof formSubmission.get;
    getForms: typeof form.get;
    getStores: typeof store.get;
    clearForms: typeof form.clearLocalState;
}

const mapDispatchToProps: MappedDispatchProps = {
    getSubmissions: formSubmission.get,
    getForms: form.get,
    getStores: store.get,
    clearForms: form.clearLocalState,
};

interface MappedStateProps {
    forms: FormStore['byId'];
    formSubmissions: FormSubmissionStore['byId'];
    formFields: FormFieldStore['byId'];
    formFieldValues: FormFieldValueStore['byId'];
    loading: boolean;
    stores: StoreStore['byId'];
}

const mapStateToProps = (state: CRMStore): MappedStateProps => ({
    forms: state.form.byId,
    formSubmissions: state.formSubmission.byId,
    formFields: state.formField.byId,
    formFieldValues: state.formFieldValue.byId,
    stores: state.store.byId,
    loading: state.formSubmission.getLoading,
});

type Props = MappedDispatchProps & MappedStateProps;

const Dashboard: React.FC<Props> = ({
    getForms,
    clearForms,
    forms,
    getSubmissions,
    formSubmissions,
    formFields,
    formFieldValues,
    getStores,
    stores,
    loading,
}): React.ReactElement => {
    const defaultDateRange: [Moment, Moment] = [moment().subtract(30, 'days'), moment()];
    const [formId, setFormId] = useState<undefined | string>(undefined);
    const [dateRange, setDateRange] = useState<RangePickerValue>(defaultDateRange);
    const pageTitle = formId != null ? `Statistics for ${forms[parseInt(formId, 10)].name}` : '';
    const formSubmissionsWithinDateRange = createdWithinDateRange<FormSubmission>(
        formSubmissions,
        dateRange[0],
        dateRange[1],
    );
    const formFieldValuesWithinDateRange = withMatchingParent<FormSubmission, FormFieldValue>(
        formSubmissionsWithinDateRange,
        formFieldValues,
        'id',
        'formSubmissionId',
    );
    useEffect(() => {
        getForms(null, {
            include: 'formFields',
        });
        getStores();
        return () => {
            clearForms();
        };
    }, []);
    useEffect(() => {
        if (formId == null) {
            return;
        }
        getSubmissions(null, {
            where: {
                formId: formId,
                and: [
                    {
                        dateCreated: { lt: dateRange[1] },
                    },
                    {
                        dateCreated: { gt: dateRange[0] },
                    },
                ],
            },
            include: 'formFieldValues',
        });
    }, [formId, dateRange]);
    const pageContent = (
        <div>
            <div style={{ marginTop: 16 }}>
                <SubmissionStatisticsHeading
                    formFieldValues={formFieldValuesWithinDateRange}
                    formSubmissions={formSubmissionsWithinDateRange}
                    dateRange={dateRange}
                />
            </div>
            <div style={{ marginTop: 16 }}>
                <StorePerformanceTable formFieldValues={formFieldValuesWithinDateRange} stores={stores} />
            </div>
        </div>
    );
    return (
        <div>
            <div>
                <Row>
                    <Col xs={24} md={12}>
                        {pageTitle != '' && dateRange[0] != null && dateRange[1] != null && (
                            <div>
                                <h2 style={{ marginTop: 0, marginBottom: 0 }}>{pageTitle}</h2>
                                <span>
                                    Showing statistics for submissions between {dateRange[0].format('MM/DD/YYYY')}{' '}
                                    {' - '}
                                    {dateRange[1].format('MM/DD/YYYY')}
                                </span>
                            </div>
                        )}
                    </Col>
                    <Col xs={24} md={12} style={{ textAlign: 'right' }}>
                        <Select
                            onChange={v => setFormId(v)}
                            value={formId}
                            style={{ minWidth: 200, marginRight: 8 }}
                            placeholder="Select Form"
                        >
                            {Object.values(forms).map(form => (
                                <Select.Option key={form.id} value={form.id}>
                                    {form.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <DatePicker.RangePicker onChange={v => setDateRange(v)} value={dateRange} allowClear={false} />
                    </Col>
                </Row>
                {pageTitle != '' && dateRange[0] != null && dateRange[1] != null && (
                    <div>
                        {loading && <Spin tip="Fetching data...">{pageContent}</Spin>}
                        {!loading && pageContent}
                    </div>
                )}
                {pageTitle == '' && (
                    <div style={{ marginTop: 16 }}>
                        <Empty description="Select a form to view metrics." />
                    </div>
                )}
            </div>
        </div>
    );
};

export default connect<MappedStateProps, MappedDispatchProps, {}, CRMStore>(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
