import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CRMStore, formSubmission } from '../../../store/modules';
import { FormSubmission, FormSubmissionStore } from '../../../store/modules/formSubmission';
import { Drawer, Skeleton, Table, Alert } from 'antd';
import { FormStore } from '../../../store/modules/form';
import { FormFieldStore } from '../../../store/modules/formField';
import { FormFieldValue, FormFieldValueStore, getHumanReadableValue } from '../../../store/modules/formFieldValue';
import { ColumnProps } from 'antd/lib/table';
import { nullToString, stripNullValues } from '../../../utilities';
import moment from 'moment';
import { UserProfile, UserProfileStore } from '../../../store/modules/userProfile';
import { RouteComponentProps, withRouter } from 'react-router';

interface MappedStateProps {
    forms: FormStore['byId'];
    formFields: FormFieldStore['byId'];
    formFieldValues: FormFieldValueStore['byId'];
    formSubmissions: FormSubmissionStore['byId'];
    userProfiles: UserProfileStore['byId'];
    currentUser: UserProfile | null;
}

interface MappedDispatchProps {
    getFormSubmissions: typeof formSubmission.get;
    patchFormSubmission: typeof formSubmission.patch;
}

interface OwnProps {
    formSubmissionId: number | null;
    visible: boolean;
    onClose: () => void;
}

const mapStateToProps = (state: CRMStore): MappedStateProps => ({
    forms: state.form.byId,
    formFields: state.formField.byId,
    formFieldValues: state.formFieldValue.byId,
    formSubmissions: state.formSubmission.byId,
    userProfiles: state.userProfile.byId,
    currentUser: state.app.app.session.currentUser,
});

const mapDispatchToProps: MappedDispatchProps = {
    getFormSubmissions: formSubmission.get,
    patchFormSubmission: formSubmission.patch,
};

type Props = MappedStateProps & MappedDispatchProps & OwnProps & RouteComponentProps;

const FormSubmissionDrawer: React.FC<Props> = ({
    onClose,
    visible,
    forms,
    formFields,
    formFieldValues,
    formSubmissions,
    formSubmissionId,
    getFormSubmissions,
    patchFormSubmission,
    userProfiles,
    currentUser,
    history,
}) => {
    let loading = false;
    const hydrate = () => {
        getFormSubmissions(formSubmissionId, {
            include: [{ form: 'formFields' }, 'formFieldValues', 'reviewedByUserProfile'],
        });
    };
    useEffect(() => {
        if (visible) {
            hydrate();
        }
    }, [formSubmissionId, visible]);
    const filteredValues: FormFieldValue[] = Object.values(formFieldValues).filter(
        (value: FormFieldValue) => value.formSubmissionId === formSubmissionId,
    );
    interface DescriptionItemProps {
        title: string;
        content: any;
    }
    const markReviewed = (e: React.MouseEvent) => {
        e.preventDefault();
        if (currentUser != null && currentUser.id != null && formSubmissionId != null) {
            patchFormSubmission(
                formSubmissionId,
                stripNullValues<FormSubmission>({
                    ...formSubmissions[formSubmissionId],
                    reviewedOn: moment().utc(),
                    reviewedByUserProfileId: currentUser.id,
                }),
            );
        }
    };
    const handleGoToFollowup = (e: React.MouseEvent) => {
        e.preventDefault();
        if (formSubmissionId != null) {
            history.push(`/followup-requests/${formSubmissions[formSubmissionId].followupRequestId}`);
        }
    };
    const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
        <div
            style={{
                fontSize: 14,
                lineHeight: '22px',
                marginBottom: 7,
                color: 'rgba(0,0,0,0.65)',
            }}
        >
            <p
                style={{
                    marginRight: 8,
                    display: 'inline-block',
                    color: 'rgba(0,0,0,0.85)',
                }}
            >
                {title}:
            </p>
            {nullToString(content)}
        </div>
    );
    const columns: ColumnProps<FormFieldValue>[] = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            render: (text, record) => formFields[record.formFieldId].label,
        },
        {
            title: 'Response',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => getHumanReadableValue(record),
        },
    ];
    const paginationConfig = {
        showSizeChanger: true,
        pageSizeOptions: ['25', '50'],
        defaultPageSize: 25,
    };
    let reviewedBy = 'Nobody';
    // @ts-ignore
    let reviewedOn: string | moment.Moment = 'Not Reviewed';
    let reviewed = false;
    if (
        formSubmissionId != null &&
        formSubmissions[formSubmissionId] != null &&
        formSubmissions[formSubmissionId].reviewedOn != null
    ) {
        // @ts-ignore (typescript is fucking dumb)
        reviewedOn = moment(formSubmissions[formSubmissionId].reviewedOn).format('LLL');
        reviewed = true;
        if (formSubmissions[formSubmissionId].reviewedByUserProfileId != null) {
            // @ts-ignore
            if (userProfiles[formSubmissions[formSubmissionId].reviewedByUserProfileId] != null) {
                // @ts-ignore
                reviewedBy = `${userProfiles[formSubmissions[formSubmissionId].reviewedByUserProfileId].firstName} ${
                    // @ts-ignore
                    userProfiles[formSubmissions[formSubmissionId].reviewedByUserProfileId].lastName
                }`;
            } else {
                hydrate();
                loading = true;
            }
        }
    }
    return (
        <Drawer
            closable={false}
            visible={visible}
            onClose={onClose}
            width={640}
            title={
                <div>
                    <h2 style={{ marginBottom: 4 }}>Form Submission #{formSubmissionId}</h2>
                    {formSubmissionId != null &&
                        formSubmissions[formSubmissionId] != null &&
                        forms[formSubmissions[formSubmissionId].formId] != null && (
                        <h4 style={{ marginBottom: 0 }}>{forms[formSubmissions[formSubmissionId].formId].name}</h4>
                    )}
                </div>
            }
        >
            <Skeleton active paragraph={{ rows: 20 }} title={false} loading={filteredValues.length === 0}>
                <div>
                    {formSubmissionId != null &&
                        formSubmissions[formSubmissionId] != null &&
                        formSubmissions[formSubmissionId].reviewRequired && (
                        <div>
                            {!loading && !reviewed && (
                                <Alert
                                    type={'warning'}
                                    message={
                                        <div className="flex justify-space-between">
                                            <span>This submission has been flagged for review.</span>
                                            <span>
                                                <a href="" onClick={markReviewed}>
                                                        Mark Reviewed
                                                </a>
                                            </span>
                                        </div>
                                    }
                                    style={{ marginBottom: 16 }}
                                />
                            )}
                            {!loading && reviewed && (
                                <Alert
                                    type={'info'}
                                    message={`This submission was reviewed by ${reviewedBy} on ${reviewedOn}`}
                                    style={{ marginBottom: 16 }}
                                />
                            )}
                            {loading && (
                                <Alert
                                    type={'info'}
                                    message={<span>Loading...</span>}
                                    style={{ marginBottom: 16 }}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div>
                    {formSubmissionId != null &&
                        formSubmissions[formSubmissionId] != null &&
                        formSubmissions[formSubmissionId].followupRequestId != null && (
                        <Alert
                            type={'info'}
                            message={
                                <div className="flex justify-space-between">
                                    <span>This submission has a corresponding followup request.</span>
                                    <span>
                                        <a href="" onClick={handleGoToFollowup}>
                                                Go To Followup Request
                                        </a>
                                    </span>
                                </div>
                            }
                            style={{ marginBottom: 16 }}
                        />
                    )}
                </div>
                <div>
                    {formSubmissionId != null && formSubmissions[formSubmissionId] != null && (
                        <Table
                            className="form-submission-values drawer-table"
                            size="small"
                            rowKey="uuid"
                            columns={columns}
                            dataSource={filteredValues}
                            pagination={paginationConfig}
                        />
                    )}
                    {formSubmissionId != null && formSubmissions[formSubmissionId] == null && (
                        <h3>Submission not found.</h3>
                    )}
                </div>
            </Skeleton>
        </Drawer>
    );
};

export default connect<MappedStateProps, MappedDispatchProps, OwnProps, CRMStore>(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(FormSubmissionDrawer));
