import React, { MouseEvent } from 'react';
import { Button, Card, Table } from 'antd';
import { ColumnFilterItem, ColumnProps } from 'antd/lib/table';
import { Store, StoreStore } from '../../../store/modules/store';
import moment from 'moment';
import { FollowupRequest, FollowupRequestStore } from '../../../store/modules/followupRequest';
import { FollowupRequestStatus, FollowupRequestStatusStore } from '../../../store/modules/followupRequestStatus';
import FollowupRequestStatusTag from './FollowupRequestStatusTag';
import { nullToString } from '../../../utilities';
import mobile from 'is-mobile';

interface Props {
    followupRequestStatuses: FollowupRequestStatusStore['byId'];
    followupRequests: FollowupRequestStore['byId'];
    stores: StoreStore['byId'];
    onViewRequest: (request: FollowupRequest) => void;
}

const FollowupRequestsTable: React.FC<Props> = ({
    followupRequestStatuses,
    followupRequests,
    stores,
    onViewRequest,
}): React.ReactElement => {
    const isMobile = mobile.isMobile();
    const getStoreFromRequest = (id: number): string => {
        if (
            followupRequests[id] &&
            followupRequests[id].storeId != null &&
            // @ts-ignore
            stores[followupRequests[id].storeId] != null
        ) {
            // @ts-ignore
            return stores[followupRequests[id].storeId].name;
        }
        return '';
    };
    const renderTools = (request: FollowupRequest) => (
        <div style={{ textAlign: 'right' }}>
            <Button htmlType="button" size="small" type="primary" onClick={(e: MouseEvent) => onViewRequest(request)}>
                View Request
            </Button>
        </div>
    );
    const sortStatusTag = (requestA: FollowupRequest, requestB: FollowupRequest) =>
        followupRequestStatuses[requestA.followupRequestStatusId].name.localeCompare(
            followupRequestStatuses[requestB.followupRequestStatusId].name,
        );

    let storesFilter: ColumnFilterItem[] = [];
    Object.values(stores).forEach((value: Store) => {
        storesFilter.push({ value: value.name, text: value.name });
    });
    let statusFilter: ColumnFilterItem[] = [];
    Object.values(followupRequestStatuses).forEach((value: FollowupRequestStatus) => {
        statusFilter.push({ value: value.id + '', text: value.name });
    });
    const columns: ColumnProps<FollowupRequest>[] = [
        {
            title: 'Requester Information',
            children: [
                {
                    title: 'First Name',
                    dataIndex: 'requesterFirstName',
                    key: 'requesterFirstName',
                    sorter: (a, b) =>
                        nullToString(a.requesterFirstName).localeCompare(nullToString(b.requesterFirstName)),
                },
                {
                    title: 'Last Name',
                    dataIndex: 'requesterLastName',
                    key: 'requesterLastName',
                    sorter: (a, b) =>
                        nullToString(a.requesterLastName).localeCompare(nullToString(b.requesterLastName)),
                },
                {
                    title: 'Phone Number',
                    dataIndex: 'requesterPhoneNumber',
                    key: 'requesterPhoneNumber',
                    sorter: (a, b) =>
                        nullToString(a.requesterPhoneNumber).localeCompare(nullToString(b.requesterPhoneNumber)),
                },
            ],
        },
        {
            title: 'Store',
            dataIndex: 'store',
            key: 'store',
            filters: storesFilter,
            onFilter: (v, r) => v === getStoreFromRequest(r.id),
            sorter: (a, b) => getStoreFromRequest(a.id).localeCompare(getStoreFromRequest(b.id)),
            render: (t, r) => getStoreFromRequest(r.id),
        },
        {
            title: 'Date Submitted',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            render: t => moment(t).format('MM/DD/YYYY - hh:mma'),
            sorter: (a, b) => moment(a.dateCreated).diff(b.dateCreated),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: statusFilter,
            onFilter: (v, r) => parseInt(v, 10) === r.followupRequestStatusId,
            render: (t, r) => (
                <FollowupRequestStatusTag followupRequest={r} followupRequestStatuses={followupRequestStatuses} />
            ),
            sorter: (a, b) => sortStatusTag(a, b),
        },
        {
            title: 'Tools',
            dataIndex: 'tools',
            key: 'tools',
            render: (t, r) => renderTools(r),
        },
    ];
    return (
        <Card title="Followup Requests">
            <Table bordered rowKey="id" size="middle" columns={columns} dataSource={Object.values(followupRequests)} />
        </Card>
    );
};

export default FollowupRequestsTable;
