import React from 'react';
import { Bar, ChartComponentProps } from 'react-chartjs-2';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { enumerateDaysBetweenDates } from '../../../utilities';
import { FormSubmission, FormSubmissionStore } from '../../../store/modules/formSubmission';
import moment from 'moment';

interface Props {
    formSubmissions: FormSubmissionStore['byId'];
    dateRange: RangePickerValue;
}

const SubmissionsPerDayChart: React.FC<Props> = ({ formSubmissions, dateRange }): React.ReactElement => {
    let dates = enumerateDaysBetweenDates(dateRange[0], dateRange[1]);
    let formatedDates: string[] = [];
    let data: ChartComponentProps['data'] = {
        labels: formatedDates,
        datasets: [
            {
                backgroundColor: 'rgba(176,216,255,.8)',
                label: 'Submissions Per Day',
                data: [],
            },
        ],
    };
    dates.forEach((value, key) => {
        data.datasets[0].data[key] = 0;
        formatedDates.push(moment(value).format('MM/DD'));
    });
    Object.values(formSubmissions).forEach((value: FormSubmission) => {
        let dataIndex = formatedDates.indexOf(moment(value.dateCreated).format('MM/DD'));
        if (dataIndex != -1) {
            data.datasets[0].data[dataIndex]++;
        }
    });
    return (
        <Bar
            data={data}
            options={{
                maintainAspectRatio: false,
                legend: { display: false },
                tooltips: {
                    callbacks: {
                        label: (i: any) => ' ' + i.yLabel + ' Submissions',
                        title: () => null,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                display: true,
                                autoSkip: true,
                                maxTicksLimit: 30,
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
            }}
        />
    );
};

export default SubmissionsPerDayChart;
