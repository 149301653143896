import React, { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';

const LoginRoute: React.FC = ({}): ReactElement => {
    return (
        <Switch>
            <Route path="/" component={Dashboard} />
        </Switch>
    );
};

export default LoginRoute;
