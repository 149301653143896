import React from 'react';
import { Card, Col, Statistic, Row } from 'antd';
import { FormSubmission, FormSubmissionStore } from '../../../store/modules/formSubmission';
import moment from 'moment';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import SubmissionsPerDayChart from './SubmissionsPerDayChart';
import { enumerateDaysBetweenDates } from '../../../utilities';
import mobile from 'is-mobile';

interface Props {
    formSubmissions: FormSubmissionStore['byId'];
    dateRange: RangePickerValue;
}

const SubmissionStatisticsHeading: React.FC<Props> = ({ formSubmissions, dateRange }): React.ReactElement => {
    const isMobile = mobile.isMobile();
    let lastSubmittedOn = 'Unknown';
    let allDatesWithinRange = enumerateDaysBetweenDates(dateRange[0], dateRange[1]);
    let averageSubmissionsPerDay = (Object.values(formSubmissions).length / allDatesWithinRange.length).toFixed(2);
    let averageRequestsPerDay = (
        Object.values(formSubmissions).filter((s: FormSubmission) => s.followupRequestId != null).length /
        allDatesWithinRange.length
    ).toFixed(2);
    Object.values(formSubmissions).forEach((value: FormSubmission) => {
        if (lastSubmittedOn === 'Unknown') {
            lastSubmittedOn = value.dateCreated;
        } else if (moment(value.dateCreated).diff(moment(lastSubmittedOn)) > 0) {
            lastSubmittedOn = value.dateCreated;
        }
    });

    lastSubmittedOn = moment(lastSubmittedOn).format('MM/DD/YYYY');
    lastSubmittedOn = lastSubmittedOn === 'Invalid date' ? '' : lastSubmittedOn;

    return (
        <div>
            <Row gutter={16}>
                <Col md={12} sm={24}>
                    <Row gutter={16} style={{ marginBottom: 16 }}>
                        <Col md={8} sm={24}>
                            <Card style={{ textAlign: 'center' }}>
                                <Statistic title="Total Submissions" value={Object.values(formSubmissions).length} />
                            </Card>
                        </Col>
                        <Col md={8} sm={24}>
                            <Card style={{ textAlign: 'center' }}>
                                <Statistic title="Last Submission" value={lastSubmittedOn} />
                            </Card>
                        </Col>
                        <Col md={8} sm={24} />
                    </Row>
                    <Row gutter={16}>
                        <Col md={8} sm={24}>
                            <Card style={{ textAlign: 'center' }}>
                                <Statistic title="Submissions Per Day" value={averageSubmissionsPerDay} />
                            </Card>
                        </Col>
                        <Col md={8} sm={24}>
                            <Card style={{ textAlign: 'center' }}>
                                <Statistic title="Requests Per Day" value={averageRequestsPerDay} />
                            </Card>
                        </Col>
                        <Col md={8} sm={24} />
                    </Row>
                </Col>
                {!isMobile && (
                    <Col md={12} sm={24}>
                        <Card title="Submissions Over Time" style={{ height: 238 }}>
                            <SubmissionsPerDayChart formSubmissions={formSubmissions} dateRange={dateRange} />
                        </Card>
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default SubmissionStatisticsHeading;
