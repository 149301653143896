import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { Icon, Dropdown, Menu } from 'antd';
import './UserMenu.css';
import { CRMStore, userProfile } from '../store';
import { UserProfile } from '../store/modules/userProfile';
import UserPasswordChangeModal from './UserPasswordChangeModal';
import { ModalFunc } from 'antd/lib/modal/Modal';

const mapStateToProps = (state: CRMStore): MappedStateProps => ({
    currentUser: state.app.app.session.currentUser,
});

interface MappedStateProps {
    currentUser: UserProfile | null;
}

const mapDispatchToProps = {
    logout: userProfile.logout,
};

interface MappedDispatchProps {
    logout: typeof userProfile.logout;
}

type Props = MappedStateProps & MappedDispatchProps;

const UserMenu: React.FC<Props> = ({ currentUser, logout }): ReactElement => {
    const [changePasswordVisible, setChangePasswordVisibility] = useState(false);
    // @ts-ignore
    const handleCloseChangePassword: ModalFunc = (): void => {
        setChangePasswordVisibility(false);
    };
    const handleShowChangePassword = (): void => {
        setChangePasswordVisibility(true);
    };
    const handleLogout = (): void => {
        logout({});
    };
    if (currentUser != null) {
        const fullName = currentUser.firstName + ' ' + currentUser.lastName;
        const menu = (
            <Menu id={'user-menu-dropdown'}>
                <Menu.Item onClick={handleShowChangePassword}>
                    <Icon type={'key'} />
                    Change Password
                </Menu.Item>
                <Menu.Item onClick={handleLogout}>
                    <Icon type={'logout'} />
                    Logout
                </Menu.Item>
            </Menu>
        );
        return (
            <div id={'user-menu'}>
                <UserPasswordChangeModal visible={changePasswordVisible} onClose={handleCloseChangePassword} />
                <Dropdown overlay={menu} placement="bottomRight">
                    <span className={'user-name'}>
                        {fullName}
                        &nbsp;
                        <Icon type={'down'} />
                    </span>
                </Dropdown>
            </div>
        );
    }
    return <div />;
};

export default connect<MappedStateProps, MappedDispatchProps, {}, CRMStore>(
    mapStateToProps,
    mapDispatchToProps,
)(UserMenu);
