import { actionCreator, getRequestAction } from '../reduxTools';
import { RequestableStoreBase, RequestResponse } from '../../types';
import { abort, error, success } from 'redux-observable-requests';
import { combineReducers } from 'redux';
import { followupRequestStatus } from '../../schemas';
import { GET as FORM_SUBMISSION_GET } from './formSubmission';

//-----------------------------------------------------
// Types
//-----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FollowupRequestStatusStore extends RequestableStoreBase<FollowupRequestStatus> {}

export interface FollowupRequestStatus {
    id: number;
    name: string;
    color: string;
    type: string;
    default: boolean;
}

export const GET = 'followupRequestStatus/GET';
export const CLEAR_LOCAL_STATE = 'followupRequestStatus/CLEAR_LOCAL_STATE';

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const get = getRequestAction(GET, 'followup-request-statuses', null, null, followupRequestStatus);
export const clearLocalState = actionCreator(CLEAR_LOCAL_STATE);

//-----------------------------------------------------
// Epics
//-----------------------------------------------------

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const byId = (
    state: FollowupRequestStatusStore['byId'] = {},
    action: RequestResponse<any>,
): FollowupRequestStatusStore['byId'] => {
    switch (action.type) {
        case CLEAR_LOCAL_STATE:
            return {};
        case success(GET):
        case success(FORM_SUBMISSION_GET):
            if (action.payload.response.followupRequestStatuses != null) {
                return { ...state, ...action.payload.response.followupRequestStatuses };
            }
            return state;
        default:
            return state;
    }
};

const getLoading = (
    state: FollowupRequestStatusStore['getLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestStatusStore['getLoading'] => {
    switch (action.type) {
        case GET:
            return true;
        case abort(GET):
        case error(GET):
        case success(GET):
            return false;
        default:
            return state;
    }
};

const postLoading = (
    state: FollowupRequestStatusStore['postLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestStatusStore['postLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

const patchLoading = (
    state: FollowupRequestStatusStore['patchLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestStatusStore['patchLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

const purgeLoading = (
    state: FollowupRequestStatusStore['purgeLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestStatusStore['purgeLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

export const reducer = combineReducers<FollowupRequestStatusStore>({
    byId,
    getLoading,
    postLoading,
    patchLoading,
    purgeLoading,
});
