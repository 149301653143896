import {
    createStore,
    applyMiddleware,
    compose,
    Store,
    Dispatch,
    Middleware,
    MiddlewareAPI,
    combineReducers,
} from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { persistStore } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';
import { generateRequestSubscriber, RequestAction, ResponseAction } from 'redux-observable-requests';
import { normalize } from 'normalizr';
import { LOGIN as USER_PROFILE_LOGIN } from './modules/userProfile';
import { actionOfType } from './reduxTools';
import { reducers } from './modules';
import { epic as rootEpic } from './modules';
import { withAuthorizationToken } from './reduxTools';

export * from './modules';

export const configurePersistor = (store: Store) => persistStore(store);

export const configureStore = () => {
    const logMiddleware: Middleware = ({ getState }: MiddlewareAPI) => (next: Dispatch) => action => {
        console.group(action.type);
        console.info('dispatching', action);
        const result = next(action);
        console.log('next state', getState());
        console.groupEnd();
        return result;
    };

    const rootPersistConfig = {
        key: 'root',
        storage,
        whitelist: ['router'],
    };

    const epicMiddleware = createEpicMiddleware();
    // const sagaMiddleware = createSagaMiddleware()
    const history = createBrowserHistory();

    const composeEnhancers =
        (typeof window !== 'undefined' &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;

    const store = createStore(
        persistReducer(
            rootPersistConfig,
            combineReducers({
                ...reducers,
                router: connectRouter(history),
            }),
        ),
        // composeEnhancers(applyMiddleware(sagaMiddleware, epicMiddleware, routerMiddleware(history), logMiddleware))
        composeEnhancers(applyMiddleware(epicMiddleware, routerMiddleware(history), logMiddleware)),
    );

    const handleSuccessfulRequest = (nextAction: ResponseAction) => {
        if (actionOfType('GET', nextAction.meta.originalAction)) {
            if (nextAction.payload != null && nextAction.meta.responseSchema != null) {
                if (nextAction.payload.response.constructor === Array) {
                    nextAction.payload.response = normalize(nextAction.payload.response, [
                        nextAction.meta.responseSchema,
                    ]).entities;
                } else {
                    nextAction.payload.response = normalize(
                        nextAction.payload.response,
                        nextAction.meta.responseSchema,
                    ).entities;
                }
                if (nextAction.meta.originalAction.type === USER_PROFILE_LOGIN) {
                    /* try {
            const ipAddress = publicIp.v4;
            store.dispatch(withAuthorizationToken(loginHistory.post({
              userProfileId: nextAction.payload.response.response.userId,
              ipAddress,
              userAgent: navigator.userAgent
            }), nextAction.payload.response.response.id))
          } catch {
            console.error('Unable to detect remote IP. Login history will not be recorded.')
          } */
                }
            }
        }
        return nextAction;
    };

    const handleRequest = (action: RequestAction) => {
        // @ts-ignore
        if (action.payload.request.url.indexOf('access_token') !== -1) {
            return action;
            // @ts-ignore
        }
        // @ts-ignore
        return withAuthorizationToken(action);
    };

    // /sagaMiddleware.run(rootSaga)
    epicMiddleware.run(
        combineEpics(
            rootEpic,
            generateRequestSubscriber({
                onRequest: handleRequest,
                onSuccess: handleSuccessfulRequest,
            }),
        ),
    );

    return { store, history };
};
