import { combineEpics } from 'redux-observable';
import * as userProfile from './userProfile';
import * as app from './app';
import * as form from './form';
import * as formField from './formField';
import * as formFieldValue from './formFieldValue';
import * as formSubmission from './formSubmission';
import * as followupRequest from './followupRequest';
import * as followupRequestComment from './followupRequestComments';
import * as followupRequestStatus from './followupRequestStatus';
import * as store from './store';

const reducers = {
    app: app.reducer,
    userProfile: userProfile.reducer,
    store: store.reducer,
    form: form.reducer,
    formField: formField.reducer,
    formFieldValue: formFieldValue.reducer,
    formSubmission: formSubmission.reducer,
    followupRequest: followupRequest.reducer,
    followupRequestComment: followupRequestComment.reducer,
    followupRequestStatus: followupRequestStatus.reducer,
};

export interface CRMStore {
    app: app.AppStore;
    userProfile: userProfile.UserProfileStore;
    store: store.StoreStore;
    form: form.FormStore;
    formField: formField.FormFieldStore;
    formFieldValue: formFieldValue.FormFieldValueStore;
    formSubmission: formSubmission.FormSubmissionStore;
    followupRequest: followupRequest.FollowupRequestStore;
    followupRequestComment: followupRequestComment.FollowupRequestCommentStore;
    followupRequestStatus: followupRequestStatus.FollowupRequestStatusStore;
}

export const epic = combineEpics(userProfile.epic, form.epic);

export default reducers;
export {
    app,
    userProfile,
    store,
    form,
    formField,
    formFieldValue,
    formSubmission,
    followupRequest,
    followupRequestComment,
    followupRequestStatus,
    reducers,
};
