import React, { ReactElement } from 'react';
import { Card, Row, Col } from 'antd';
import './Login.css';
import LoginForm from './components/LoginForm';

const Login: React.FC = (): ReactElement => {
    return (
        <div className={'loginContainer'}>
            <Row className={'loginContent'}>
                <Col
                    lg={{
                        offset: 9,
                        span: 6,
                    }}
                    sm={{
                        offset: 6,
                        span: 12,
                    }}
                    xs={{
                        offset: 3,
                        span: 18,
                    }}
                >
                    <Card title={'CRM Login'}>
                        <LoginForm />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Login;
