import React from 'react';
import { Card, Col, Statistic, Row } from 'antd';
import { FormSubmission, FormSubmissionStore } from '../../../store/modules/formSubmission';
import { FormFieldValue, FormFieldValueStore } from '../../../store/modules/formFieldValue';
import moment from 'moment';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import SubmissionsPerDayChart from './SubmissionsPerDayChart';

interface Props {
    formSubmissions: FormSubmissionStore['byId'];
    formFieldValues: FormFieldValueStore['byId'];
    dateRange: RangePickerValue;
}

const SubmissionStatisticsHeading: React.FC<Props> = ({
    formSubmissions,
    formFieldValues,
    dateRange,
}): React.ReactElement => {
    let averageRating = 0;
    let ratingCount = 0;
    let ratingSum = 0;
    let lastSubmittedOn = 'Unknown';

    Object.values(formFieldValues).forEach((value: FormFieldValue) => {
        if (value.formFieldParseAs === 'rating') {
            ratingCount++;
            ratingSum += parseInt(value.value, 10);
        }
    });

    Object.values(formSubmissions).forEach((value: FormSubmission) => {
        if (lastSubmittedOn === 'Unknown') {
            lastSubmittedOn = value.dateCreated;
        } else if (moment(value.dateCreated).diff(moment(lastSubmittedOn)) > 0) {
            lastSubmittedOn = value.dateCreated;
        }
    });

    averageRating = parseFloat((ratingSum / ratingCount).toFixed(2));
    averageRating = isNaN(averageRating) ? 0 : averageRating;
    lastSubmittedOn = moment(lastSubmittedOn).format('MM/DD/YYYY');
    lastSubmittedOn = lastSubmittedOn === 'Invalid date' ? '' : lastSubmittedOn;

    return (
        <div>
            <Row gutter={16}>
                <Col span={4}>
                    <Card style={{ textAlign: 'center' }}>
                        <Statistic title="Total Submissions" value={Object.values(formSubmissions).length} />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{ textAlign: 'center' }}>
                        <Statistic title="Average Rating" value={averageRating} />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card style={{ textAlign: 'center' }}>
                        <Statistic title="Last Submission" value={lastSubmittedOn} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Submissions Over Time">
                        <SubmissionsPerDayChart formSubmissions={formSubmissions} dateRange={dateRange} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default SubmissionStatisticsHeading;
