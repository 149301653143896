import React from 'react';
import { Tag } from 'antd';
import { FollowupRequest } from '../../../store/modules/followupRequest';
import { FollowupRequestStatusStore } from '../../../store/modules/followupRequestStatus';

interface Props {
    followupRequestStatuses: FollowupRequestStatusStore['byId'];
    followupRequest: FollowupRequest;
    style?: object;
}

const FollowupRequestStatusTag: React.FC<Props> = ({
    style,
    followupRequestStatuses,
    followupRequest,
}): React.ReactElement => {
    const renderStatusTag = () => {
        const status = followupRequestStatuses[followupRequest.followupRequestStatusId];
        if (status == null) {
            return (
                <Tag style={style} title="unknown" color="grey">
                    unknown
                </Tag>
            );
        }
        return (
            <Tag style={style} title={status.name} color={status.color}>
                {status.name}
            </Tag>
        );
    };
    return renderStatusTag();
};

export default FollowupRequestStatusTag;
