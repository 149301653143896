import React, { useEffect, useState } from 'react';
import { app, CRMStore, formSubmission, store } from '../../store/modules';
import { connect } from 'react-redux';
import moment from 'moment';
import { DatePicker, Row, Col, Spin, Select, Empty } from 'antd';
import FollowupStatisticsHeading from './components/FollowupStatisticsHeading';
import { StoreStore } from '../../store/modules/store';
import { FollowupRequest, FollowupRequestStore } from '../../store/modules/followupRequest';
import FollowupRequestsTable from './components/FollowupRequestsTable';
import { createdWithinDateRange } from '../../store/selectors';
import { FollowupRequestStatusStore } from '../../store/modules/followupRequestStatus';
import { AppStore } from '../../store/modules/app/app';
import { followupRequestDrawer } from '../../store/modules/app';
import { FormSubmissionStore } from '../../store/modules/formSubmission';
import { RouteComponentProps, withRouter } from 'react-router';
import FollowupRequestDrawer from './components/FollowupRequestDrawer';
import { FormStore } from '../../store/modules/form';
import mobile from 'is-mobile';

interface MappedDispatchProps {
    setDateRange: typeof app.app.setDateRange;
    getSubmissions: typeof formSubmission.get;
    openFollowupRequestDrawer: typeof followupRequestDrawer.open;
    setFormId: typeof app.app.setFormId;
}

const mapDispatchToProps: MappedDispatchProps = {
    setDateRange: app.app.setDateRange,
    getSubmissions: formSubmission.get,
    openFollowupRequestDrawer: followupRequestDrawer.open,
    setFormId: app.app.setFormId,
};

interface MappedStateProps {
    forms: FormStore['byId'];
    formSubmissions: FormSubmissionStore['byId'];
    followupRequests: FollowupRequestStore['byId'];
    followupRequestStatuses: FollowupRequestStatusStore['byId'];
    dateRange: AppStore['dateRange'];
    stores: StoreStore['byId'];
    formId: AppStore['formId'];
    getSubmissionsLoading: FormSubmissionStore['getLoading'];
}

const mapStateToProps = (state: CRMStore): MappedStateProps => ({
    forms: state.form.byId,
    formSubmissions: state.formSubmission.byId,
    followupRequests: state.followupRequest.byId,
    followupRequestStatuses: state.followupRequestStatus.byId,
    dateRange: state.app.app.dateRange,
    stores: state.store.byId,
    formId: state.app.app.formId,
    getSubmissionsLoading: state.formSubmission.getLoading,
});

interface Match {
    id: string | undefined;
}

type Props = MappedDispatchProps & MappedStateProps & RouteComponentProps<Match>;

const FollowupRequests: React.FC<Props> = ({
    getSubmissions,
    formSubmissions,
    followupRequests,
    followupRequestStatuses,
    stores,
    setDateRange,
    dateRange,
    openFollowupRequestDrawer,
    getSubmissionsLoading,
    match,
    history,
    formId,
    setFormId,
    forms,
}): React.ReactElement => {
    const isMobile = mobile.isMobile();
    const filtersTextAlign = isMobile ? 'left' : 'right';
    const pageTitle = formId != null ? `Followup Requests for ${forms[formId].name}` : '';
    const [loading, setLoading] = useState<boolean>(false);
    const followupRequestId = match.params.id != null ? parseInt(match.params.id, 10) : null;
    const hydrate = (silent: boolean) => {
        if (!silent) {
            setLoading(true);
        }
        getSubmissions(null, {
            where: {
                and: [
                    {
                        dateSubmitted: { lt: dateRange[1] },
                    },
                    {
                        dateSubmitted: { gt: dateRange[0] },
                    },
                    { formId: formId },
                ],
            },
            include: [
                { followupRequest: [{ followupRequestComments: 'userProfile' }, 'followupRequestStatus', 'store'] },
                { form: 'formFields' },
            ],
        });
    };
    let followupRequestsWithinDateRange = createdWithinDateRange<FollowupRequest>(
        followupRequests,
        dateRange[0],
        dateRange[1],
    );
    Object.keys(followupRequestsWithinDateRange).forEach((key: string) => {
        const id = parseInt(key, 10);
        if (formSubmissions[followupRequestsWithinDateRange[id].formSubmissionId] != null) {
            if (formSubmissions[followupRequestsWithinDateRange[id].formSubmissionId].formId !== formId) {
                delete followupRequestsWithinDateRange[id];
            }
        }
    });
    useEffect(() => {
        if (!getSubmissionsLoading) {
            setLoading(false);
        }
    }, [followupRequests, getSubmissionsLoading]);
    useEffect(() => {
        const updateInterval = setInterval(() => {
            hydrate(true);
        }, 1000 * 15);
        return () => {
            clearInterval(updateInterval);
        };
    }, []);
    useEffect(() => {
        hydrate(false);
    }, [formId, dateRange]);
    const handleSelectFollowup = (request: FollowupRequest) => {
        history.push(`/followup-requests/${request.id}`);
        openFollowupRequestDrawer(request.id);
    };
    const handleCloseFollowup = () => {
        history.push(`/followup-requests`);
    };
    const pageContent = (
        <div>
            <div style={{ marginTop: 16 }}>
                <FollowupStatisticsHeading />
            </div>
            <div style={{ marginTop: 16 }}>
                <FollowupRequestsTable
                    followupRequestStatuses={followupRequestStatuses}
                    followupRequests={followupRequestsWithinDateRange}
                    onViewRequest={handleSelectFollowup}
                    stores={stores}
                />
            </div>
        </div>
    );
    return (
        <div>
            <div>
                <Row>
                    <Col xs={24} md={12}>
                        {dateRange[0] != null && dateRange[1] != null && (
                            <div>
                                <h2 style={{ marginTop: 0, marginBottom: 0 }}>{pageTitle}</h2>
                                <span>
                                    Showing followup requests submitted between{' '}
                                    {moment(dateRange[0]).format('MM/DD/YYYY')}
                                    {' - '}
                                    {moment(dateRange[1]).format('MM/DD/YYYY')}
                                </span>
                            </div>
                        )}
                    </Col>
                    <Col xs={24} md={12} style={{ textAlign: filtersTextAlign }}>
                        <Select
                            onChange={v => setFormId(parseInt(v + '', 10))}
                            value={formId}
                            style={{ minWidth: 200, marginRight: 8 }}
                            placeholder="Select Form"
                        >
                            {Object.values(forms).map(form => (
                                <Select.Option key={form.id} value={form.id}>
                                    {form.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <DatePicker.RangePicker
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={v => setDateRange(v)}
                            value={[moment(dateRange[0]), moment(dateRange[1])]}
                            allowClear={false}
                        />
                    </Col>
                </Row>
                {pageTitle != '' && dateRange[0] != null && dateRange[1] != null && (
                    <div>
                        {loading && <Spin tip="Fetching data...">{pageContent}</Spin>}
                        {!loading && pageContent}
                    </div>
                )}
                {pageTitle == '' && (
                    <div style={{ marginTop: 16 }}>
                        <Empty description="Select a form to view metrics." />
                    </div>
                )}
            </div>
            <FollowupRequestDrawer
                onClose={handleCloseFollowup}
                followupRequestId={followupRequestId}
                visible={followupRequestId != null}
            />
        </div>
    );
};

export default connect<MappedStateProps, MappedDispatchProps, {}, CRMStore>(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(FollowupRequests));
