import React from 'react';
import { Card, Table } from 'antd';
import { FormFieldValue, FormFieldValueStore } from '../../../store/modules/formFieldValue';
import { ColumnProps } from 'antd/lib/table';
import { Store, StoreStore } from '../../../store/modules/store';
import { IterableObject } from '../../../types';

interface Props {
    formFieldValues: FormFieldValueStore['byId'];
    stores: StoreStore['byId'];
}

const StorePerformanceTable: React.FC<Props> = ({ formFieldValues, stores }): React.ReactElement => {
    interface Stat {
        key: number;
        store: string;
        surveyCount: number;
        averageRating: number;
        maxRating: number;
        minRating: number;
        ratingCount: number;
        ratingSum: number;
        commentCount: number;
        contactRequests: number;
    }

    type Stats = IterableObject<Stat>;

    let stats: Stats = {};

    Object.values(formFieldValues).forEach((value: FormFieldValue) => {
        if (value.formFieldParseAs === 'storeId') {
            const submissionId = value.formSubmissionId;
            const storeId = parseInt(value.value, 10);
            if (stats[storeId] == null) {
                stats[storeId] = {
                    key: storeId,
                    store: stores[storeId].name,
                    surveyCount: 0,
                    averageRating: 0,
                    maxRating: 0,
                    minRating: 0,
                    ratingSum: 0,
                    ratingCount: 0,
                    commentCount: 0,
                    contactRequests: 0,
                };
            }
            stats[storeId].surveyCount++;
            Object.values(formFieldValues)
                .filter((v: FormFieldValue) => v.formSubmissionId === submissionId)
                .forEach((value: FormFieldValue) => {
                    switch (value.formFieldParseAs) {
                        case 'rating':
                            let r = parseInt(value.value, 10);
                            stats[storeId].maxRating = r > stats[storeId].maxRating ? r : stats[storeId].maxRating;
                            stats[storeId].minRating = r < stats[storeId].minRating ? r : stats[storeId].minRating;
                            stats[storeId].ratingSum += r;
                            stats[storeId].ratingCount++;
                            break;
                        case 'comment':
                            stats[storeId].commentCount++;
                            break;
                        case 'requestFollowup':
                            if (value.value != '0') {
                                stats[storeId].contactRequests++;
                            }
                            break;
                        default:
                            break;
                    }
                });
        }
    });

    Object.keys(stats).forEach((v: string) => {
        const k = parseInt(v, 10);
        stats[k].averageRating = parseFloat((stats[k].ratingSum / stats[k].ratingCount).toFixed(2));
    });

    const columns: ColumnProps<Stat>[] = [
        {
            title: 'Store',
            dataIndex: 'store',
            key: 'store',
            sorter: (a, b) => a.store.localeCompare(b.store),
        },
        {
            title: 'Total Submissions',
            dataIndex: 'surveyCount',
            key: 'surveyCount',
            sorter: (a, b) => a.surveyCount - b.surveyCount,
        },
        {
            title: 'Average Rating',
            dataIndex: 'averageRating',
            key: 'averageRating',
            sorter: (a, b) => a.averageRating - b.averageRating,
        },
        {
            title: 'Comment Count',
            dataIndex: 'commentCount',
            key: 'commentCount',
            sorter: (a, b) => a.commentCount - b.commentCount,
        },
        {
            title: 'Contact Requests',
            dataIndex: 'contactRequests',
            key: 'contactRequests',
            sorter: (a, b) => a.contactRequests - b.contactRequests,
        },
    ];

    return (
        <Card title="Store Performance">
            <Table size="middle" columns={columns} dataSource={Object.values(stats)} />
        </Card>
    );
};

export default StorePerformanceTable;
