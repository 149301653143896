import { DataAction } from '../../../types';
import { combineReducers } from 'redux';
import { actionCreator, dataActionCreator } from '../../reduxTools';
import {
    GET as USER_PROFILE_GET,
    LOGIN as USER_PROFILE_LOGIN,
    LOGOUT as USER_PROFILE_LOGOUT,
    UserProfile,
} from '../userProfile';
import { error, success } from 'redux-observable-requests';
// @ts-ignore
import storage from 'redux-persist/es/storage/index';
import { persistReducer } from 'redux-persist';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import moment from 'moment';

//-----------------------------------------------------
// Types
//-----------------------------------------------------

export interface Notification {
    type: string;
    details: string;
    message: string;
}

export interface AppSessionStore {
    currentUser: UserProfile | null;
    loginError: boolean;
    id: string | null;
}

export interface AppStore {
    pageTitle: string;
    pageError: string;
    pendingNotifications: Notification[] | [];
    formId: number | null;
    dateRange: RangePickerValue;
    session: AppSessionStore;
}

export const SET_FORM_ID = 'app/SET_FORM_ID';
export const SET_DATE_RANGE = 'app/SET_DATE_RANGE';
export const SET_PAGE_TITLE = 'app/SET_PAGE_TITLE';
export const SET_PAGE_ERROR = 'app/SET_PAGE_ERROR';
export const POST_NOTIFICATION = 'app/POST_NOTIFICATION';
export const PURGE_ALL_NOTIFICATIONS = 'app/PURGE_ALL_NOTIFICATIONS';

export type SET_FORM_ID = typeof SET_FORM_ID;
export type SET_DATE_RANGE = typeof SET_DATE_RANGE;
export type SET_PAGE_TITLE = typeof SET_PAGE_TITLE;
export type SET_PAGE_ERROR = typeof SET_PAGE_ERROR;
export type POST_NOTIFICATION = typeof POST_NOTIFICATION;
export type PURGE_ALL_NOTIFICATIONS = typeof PURGE_ALL_NOTIFICATIONS;

export type AppAction =
    | SET_FORM_ID
    | SET_DATE_RANGE
    | SET_PAGE_TITLE
    | SET_PAGE_ERROR
    | POST_NOTIFICATION
    | PURGE_ALL_NOTIFICATIONS;

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const setFormId = dataActionCreator<number, SET_FORM_ID>(SET_FORM_ID);
export const setDateRange = dataActionCreator<RangePickerValue, SET_DATE_RANGE>(SET_DATE_RANGE);
export const setPageTitle = dataActionCreator<string, SET_PAGE_TITLE>(SET_PAGE_TITLE);
export const setPageError = dataActionCreator<string, SET_PAGE_ERROR>(SET_PAGE_ERROR);
export const postNotification = dataActionCreator<Notification, POST_NOTIFICATION>(POST_NOTIFICATION);
export const purgeAllNotifications = actionCreator<PURGE_ALL_NOTIFICATIONS>(PURGE_ALL_NOTIFICATIONS);

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const pageTitle = (state: AppStore['pageTitle'] = '', action: DataAction<any, AppAction>): AppStore['pageTitle'] => {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return action.payload;
        default:
            return state;
    }
};

const pageError = (state: AppStore['pageError'] = '', action: DataAction<any, AppAction>): AppStore['pageError'] => {
    switch (action.type) {
        case SET_PAGE_ERROR:
            return action.payload;
        default:
            return state;
    }
};

const dateRange = (
    state: RangePickerValue = [
        moment()
            .subtract(30, 'days')
            .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            }),
        moment().set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
        }),
    ],
    action: DataAction<RangePickerValue, AppAction>,
): AppStore['dateRange'] => {
    switch (action.type) {
        case SET_DATE_RANGE:
            // @ts-ignore
            return action.payload;
        default:
            return state;
    }
};

const formId = (state: null | number = null, action: DataAction<number, AppAction>): AppStore['formId'] => {
    switch (action.type) {
        case SET_FORM_ID:
            // @ts-ignore
            return action.payload;
        default:
            return state;
    }
};

const pendingNotifications = (
    state: AppStore['pendingNotifications'] = [],
    action: DataAction<Notification, AppAction>,
): AppStore['pendingNotifications'] => {
    switch (action.type) {
        case POST_NOTIFICATION:
            // @ts-ignore
            return [...state, { ...action.payload }];
        case PURGE_ALL_NOTIFICATIONS:
            return [];
        default:
            return state;
    }
};

const currentUser = (
    state: AppSessionStore['currentUser'] = null,
    action: DataAction<any, AppAction>,
): AppSessionStore['currentUser'] => {
    switch (action.type) {
        case success(USER_PROFILE_LOGIN):
            return JSON.parse(JSON.stringify(action.payload.response.user));
        case success(USER_PROFILE_GET):
            if (state != null && state.id != null && action.payload.response.userProfiles[state.id] != null) {
                return { ...action.payload.response.userProfiles[state.id] };
            }
            return state;
        case error(USER_PROFILE_LOGIN):
        case error(USER_PROFILE_LOGOUT):
        case success(USER_PROFILE_LOGOUT):
            return null;
        default:
            return state;
    }
};

const loginError = (
    state: AppSessionStore['loginError'] = false,
    action: DataAction<Notification, AppAction>,
): AppSessionStore['loginError'] => {
    switch (action.type) {
        case error(USER_PROFILE_LOGIN):
            return true;
        case success(USER_PROFILE_LOGIN):
        case success(USER_PROFILE_LOGOUT):
            return false;
        default:
            return state;
    }
};

const id = (
    state: AppSessionStore['id'] = null,
    action: DataAction<Notification, AppAction>,
): AppSessionStore['id'] => {
    switch (action.type) {
        case success(USER_PROFILE_LOGIN):
            // @ts-ignore
            return JSON.parse(JSON.stringify(action.payload.response.id));
        case error(USER_PROFILE_LOGIN):
        case success(USER_PROFILE_LOGOUT):
            return null;
        default:
            return state;
    }
};

const appPersistConfig = {
    key: 'app',
    storage: storage,
    whitelist: ['session'],
    blacklist: ['pendingNotifications', 'dateRange'],
};

const session = combineReducers<AppSessionStore>({
    currentUser,
    loginError,
    id,
});

export const reducer = persistReducer(
    appPersistConfig,
    combineReducers<AppStore>({
        pageTitle,
        pageError,
        pendingNotifications,
        dateRange,
        formId,
        session,
    }),
);
