import moment, { Moment } from 'moment';
import { IterableObject } from './types';

export const enumerateDaysBetweenDates = (startDate: Moment | undefined, endDate: Moment | undefined) => {
    let dates = [];
    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toDate());
    }

    return dates;
};

export const sortString = (a: string, b: string) => {
    return a.localeCompare(b);
};

export const sortNullableString = (a: string | null, b: string | null) => {
    if (a == null && b != null) {
        return -1;
    }
    if (b == null && a != null) {
        return 1;
    }
    if (b == null && a == null) {
        return 0;
    }
    if (a != null && b != null) {
        return a.localeCompare(b);
    }
    return 0;
};

export const sortBoolean = (a: boolean | null, b: boolean | null) => {
    if (a != null && b != null) {
        if (a && b) {
            return 0;
        }
        if (!a && !b) {
            return 0;
        }
        if (a && !b) {
            return 1;
        }
        if (!a && b) {
            return -1;
        }
    }
    if (a != null && b == null) {
        return 1;
    }
    if (a == null && b != null) {
        return -1;
    }
    if (a == null && b == null) {
        return 0;
    }
    return 0;
};

export const nullIndexToString = (index: any, property: any, search: any[] | IterableObject<any>) => {
    let v = null;
    try {
        v = search[index];
    } catch (e) {
        console.error(e);
    }
    if (v != null) {
        try {
            v = v[property];
        } catch (e) {
            console.error(e);
        }
    }
    return v == null ? '' : v;
};

export const nullToString = (value: any): any => {
    if (value == null) {
        return '';
    }
    return value;
};

export const formatPhoneNumber = (value: string) => {
    var cleaned = ('' + value).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
};

export const stripNullValues = <T extends { [key: string]: any }>(obj: T): T => {
    let newObj: T = { ...obj };
    Object.keys(obj).forEach(
        (key): void => {
            if (obj[key] == null) {
                delete newObj[key];
            }
        },
    );
    return newObj;
};
