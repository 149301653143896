import React, { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import FollowupRequests from './FollowupRequests';

const FollowupRequestsRoute: React.FC = ({}): ReactElement => {
    return (
        <Switch>
            <Route path="/followup-requests/:id" component={FollowupRequests} />
            <Route path="/followup-requests" component={FollowupRequests} />
        </Switch>
    );
};

export default FollowupRequestsRoute;
