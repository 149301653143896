import React, { FormEvent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { userProfile } from '../../../store';
import { FormComponentProps } from 'antd/lib/form';

const mapDispatchToProps = {
    login: userProfile.login,
};

interface Props {
    login: typeof userProfile.login;
}

const Search: React.FC<Props & FormComponentProps> = ({ form, login }): ReactElement => {
    const handleSubmit = (e: FormEvent): void => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                login({
                    username: values.username,
                    password: values.password,
                });
            }
        });
    };
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item label="Username">
                {form.getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Please enter a username.' }],
                })(<Input />)}
            </Form.Item>
            <Form.Item label="Password">
                {form.getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please enter a password.' }],
                })(<Input type={'password'} />)}
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
                <Button type={'primary'} htmlType={'submit'}>
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
};

export default connect(
    null,
    mapDispatchToProps,
)(Form.create()<any>(Search));
