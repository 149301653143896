import { IterableObject } from '../types';
import { Moment } from 'moment';
import moment from 'moment';

interface WithDateCreated {
    [key: string]: any;
    dateCreated: Moment | string;
}

export const createdWithinDateRange = <T extends WithDateCreated>(
    values: IterableObject<T>,
    start: Moment | string | undefined,
    end: Moment | string | undefined,
): IterableObject<T> => {
    let returnValues: IterableObject<T> = {};
    if (start != null || end != null) {
        Object.keys(values).forEach(key => {
            const k = parseInt(key, 10);
            if (moment(values[k].dateCreated).diff(start) >= 0 && moment(values[k].dateCreated).diff(end) <= 0) {
                returnValues[k] = { ...values[k] };
            }
        });
    }
    return returnValues;
};

export const withMatchingParent = <P, C>(
    parents: IterableObject<P>,
    children: IterableObject<C>,
    parentField: keyof P,
    childField: keyof C,
): IterableObject<C> => {
    let returnValues: IterableObject<C> = {};
    Object.keys(children).forEach(key => {
        const k = parseInt(key, 10);
        Object.values(parents).forEach(parent => {
            if (children[k][childField] === parent[parentField]) {
                returnValues[k] = { ...children[k] };
            }
        });
    });
    return returnValues;
};
