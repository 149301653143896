import React from 'react';
import { Card, Col, Statistic, Row } from 'antd';
import { FormSubmissionStore } from '../../../store/modules/formSubmission';
import { FollowupRequest, FollowupRequestStore } from '../../../store/modules/followupRequest';
import { CRMStore } from '../../../store/modules';
import { AppStore } from '../../../store/modules/app/app';
import { connect } from 'react-redux';
import { createdWithinDateRange } from '../../../store/selectors';
import { Moment } from 'moment';
import moment from 'moment';

interface MappedStateProps {
    formSubmissions: FormSubmissionStore['byId'];
    followupRequests: FollowupRequestStore['byId'];
    dateRange: AppStore['dateRange'];
}

type Props = MappedStateProps;

const mapStateToProps = (state: CRMStore): MappedStateProps => ({
    formSubmissions: state.formSubmission.byId,
    followupRequests: state.followupRequest.byId,
    dateRange: state.app.app.dateRange,
});

const FollowupStatisticsHeading: React.FC<Props> = ({
    formSubmissions,
    followupRequests,
    dateRange,
}): React.ReactElement => {
    let totalSubmissions = Object.values(createdWithinDateRange(formSubmissions, dateRange[0], dateRange[1])).length;
    let totalFollowupRequests = 0;
    let totalOpenFollowupRequests = 0;
    let oldestOpenRequestId: number | null = null;
    let latestOpenRequestId: number | null = null;
    let oldestOpenRequestDate: string | Moment = '';
    let latestOpenRequestDate: string | Moment = '';
    Object.values(createdWithinDateRange(followupRequests, dateRange[0], dateRange[1])).forEach(
        (value: FollowupRequest) => {
            totalFollowupRequests++;
            if (value.followupRequestStatusId != 3) {
                totalOpenFollowupRequests++;
            }
            if (oldestOpenRequestId == null) {
                oldestOpenRequestId = value.id;
            } else {
                if (
                    followupRequests[oldestOpenRequestId] == null ||
                    moment(followupRequests[oldestOpenRequestId].dateCreated).diff(moment(value.dateCreated)) > 0
                ) {
                    oldestOpenRequestId = value.id;
                }
            }
            if (latestOpenRequestId == null) {
                latestOpenRequestId = value.id;
            } else {
                if (
                    followupRequests[latestOpenRequestId] == null ||
                    moment(followupRequests[latestOpenRequestId].dateCreated).diff(moment(value.dateCreated)) < 0
                ) {
                    latestOpenRequestId = value.id;
                }
            }
        },
    );

    if (oldestOpenRequestId != null && followupRequests[oldestOpenRequestId] != null) {
        oldestOpenRequestDate = moment(followupRequests[oldestOpenRequestId].dateCreated).format('MM/DD/YYYY');
    }
    if (latestOpenRequestId != null && followupRequests[latestOpenRequestId] != null) {
        latestOpenRequestDate = moment(followupRequests[latestOpenRequestId].dateCreated).format('MM/DD/YYYY');
    }

    return (
        <div>
            <Row gutter={16}>
                <Col md={4} sm={24}>
                    <Card style={{ textAlign: 'center' }}>
                        <Statistic title="Total Submissions" value={totalSubmissions} />
                    </Card>
                </Col>
                <Col md={4} sm={24}>
                    <Card style={{ textAlign: 'center' }}>
                        <Statistic title="Total Followup Requests" value={totalFollowupRequests} />
                    </Card>
                </Col>
                <Col md={4} sm={24}>
                    <Card style={{ textAlign: 'center' }}>
                        <Statistic title="Total Open Followup Requests" value={totalOpenFollowupRequests} />
                    </Card>
                </Col>
                <Col md={4} sm={24}>
                    <Card style={{ textAlign: 'center' }}>
                        <Statistic title="Oldest Open Request" value={oldestOpenRequestDate} />
                    </Card>
                </Col>
                <Col md={4} sm={24}>
                    <Card style={{ textAlign: 'center' }}>
                        <Statistic title="Latest Open Request" value={latestOpenRequestDate} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default connect<MappedStateProps, {}, {}, CRMStore>(mapStateToProps)(FollowupStatisticsHeading);
