import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import AppNavigation from './components/AppNavigation';
import AppNotificationDrawer from './components/AppNotificationDrawer';
import { CRMStore } from './store';
import './App.less';
import { UserProfile } from './store/modules/userProfile';
import Dashboard from './routes/dashboard';
import Submissions from './routes/submissions';
import FollowupRequests from './routes/followup-requests';
import Login from './routes/login';

const mapStateToProps = (state: CRMStore): MappedStateProps => ({
    currentUser: state.app.app.session.currentUser,
});

interface MappedStateProps {
    currentUser: UserProfile | null;
}

const mapDispatchToProps = {
    // verifyActiveSession: userProfile.verifyActiveSession,
};

type Props = MappedStateProps;

class App extends Component<Props & RouteComponentProps> {
    public componentDidMount = (): void => {
        const { currentUser } = this.props;
        if (currentUser != null) {
            //    verifyActiveSession(currentUser.id);
        }
    };
    public render(): ReactNode {
        const { currentUser, location } = this.props;
        const atLogin = location.pathname === '/login';
        return (
            <div>
                <AppNotificationDrawer />
                {currentUser != null && (
                    <AppNavigation>
                        {atLogin && <Redirect from={'/login'} to={'/submissions'} />}
                        <Redirect exact from={'/'} to={'/submissions'} />
                        <Switch>
                            // TODO redefined routes.
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/followup-requests" component={FollowupRequests} />
                            <Route path="/submissions" component={Submissions} />
                        </Switch>
                    </AppNavigation>
                )}
                {currentUser == null && (
                    <Switch>
                        {!atLogin && <Redirect to={'/login'} />}
                        <Route path="/login" component={Login} />
                    </Switch>
                )}
            </div>
        );
    }
}

export default withRouter(
    connect<MappedStateProps, {}, {}, CRMStore>(
        mapStateToProps,
        mapDispatchToProps,
    )(App),
);
