import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Progress } from 'antd';
import { userProfile, CRMStore } from '../store';
import { FormComponentProps, ValidationRule } from 'antd/lib/form';
import { ProgressProps } from 'antd/lib/progress/progress';
import { ModalFunc } from 'antd/lib/modal/Modal';
import { UserProfile } from '../store/modules/userProfile';

const mapDispatchToProps = {
    changePassword: userProfile.changePassword,
};

const mapStateToProps = (state: CRMStore) => ({
    currentUser: state.app.app.session.currentUser,
    loading: state.userProfile.postLoading,
});

interface MappedDispatchProps {
    changePassword: typeof userProfile.changePassword;
}

interface MappedStateProps {
    currentUser: UserProfile | null;
    loading: boolean;
}

interface Props {
    onClose: ModalFunc;
    visible: boolean;
}

const UserPasswordChangeModal: React.FC<MappedStateProps & MappedDispatchProps & Props & FormComponentProps> = ({
    loading,
    changePassword,
    onClose,
    visible,
    form,
}) => {
    const [passwordStrength, setPasswordStrength] = useState(0);
    const onSubmit = () => {
        form.validateFields((err, values) => {
            if (!err) {
                changePassword({
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                });
                setPasswordStrength(0);
                form.resetFields();
                setTimeout(onClose, 1000);
            }
        });
    };

    const confirmPasswordValidator = (rule: ValidationRule, password: string, callback: Function) => {
        const fields = form.getFieldsValue(['newPassword', 'confirmNewPassword']);
        if (fields['newPassword'] !== fields['confirmNewPassword']) {
            callback(Error('Both passwords must match.'));
        } else {
            callback();
        }
    };

    const passwordComplexityRating = (password: string) => {
        let rating = 0;
        if (password.length >= 8) {
            rating++;
        }
        if (password.length >= 10) {
            rating++;
        }
        if (password.match(/[|\\/~^:,;?!&%$@*+]/)) {
            rating++;
        }
        if (/[A-Z]/.test(password)) {
            rating++;
        }
        if (/[0-9]/.test(password)) {
            rating++;
        }
        return rating;
    };

    const passwordComplexityValidator = (rule: ValidationRule, password: string, callback: Function) => {
        password = password + '';
        setPasswordStrength(passwordComplexityRating(password));
        if (password.length < 8) {
            return callback(new Error('Password must be at least 8 characters long.'));
        }
        if (!/[A-Z]/.test(password)) {
            return callback(new Error('Password must contain at least one upper case letter.'));
        }
        if (!/[0-9]/.test(password)) {
            return callback(new Error('Password must contain at least one number.'));
        }
        return callback();
    };

    let strengthStatus: ProgressProps['status'] = passwordStrength < 4 ? 'exception' : 'active';
    strengthStatus = passwordStrength >= 5 ? 'success' : strengthStatus;

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const passwordForm = (
        <Form layout={'horizontal'}>
            <Form.Item label={'Current Password'} {...formItemLayout}>
                {form.getFieldDecorator('oldPassword', {
                    rules: [{ required: true, message: 'Please enter your current password.' }],
                })(<Input type={'password'} />)}
            </Form.Item>
            <Form.Item label={'New Password'} {...formItemLayout}>
                {form.getFieldDecorator('newPassword', {
                    rules: [
                        { required: true, message: 'Please enter your new password.' },
                        {
                            validator: passwordComplexityValidator,
                            message:
                                'Passwords must be at least 8 characters and include at least one upper case letter and one number.',
                        },
                    ],
                })(<Input type={'password'} />)}
            </Form.Item>
            <Form.Item label={'Confirm New Password'} {...formItemLayout}>
                {form.getFieldDecorator('confirmNewPassword', {
                    rules: [
                        { required: true, message: 'Please confirm your new password.' },
                        { validator: confirmPasswordValidator, message: 'Both passwords must match.' },
                    ],
                })(<Input type={'password'} />)}
            </Form.Item>
            <Form.Item label={'Password Strength'} {...formItemLayout}>
                <Progress showInfo={false} percent={(passwordStrength / 5) * 100} status={strengthStatus} />
            </Form.Item>
        </Form>
    );

    return (
        <Modal
            title={'Change Password'}
            visible={visible}
            onOk={onSubmit}
            confirmLoading={loading}
            onCancel={onClose}
            okText={'Change Password'}
        >
            {passwordForm}
        </Modal>
    );
};

export default connect<MappedStateProps, MappedDispatchProps, Props, CRMStore>(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()<any>(UserPasswordChangeModal));
