import React, { MouseEvent } from 'react';
import { FormSubmission, FormSubmissionStore } from '../../../store/modules/formSubmission';
import { Badge, Button, Card, Table, Tag } from 'antd';
import { ColumnFilterItem, ColumnProps } from 'antd/lib/table';
import { Store, StoreStore } from '../../../store/modules/store';
import { nullIndexToString, sortBoolean, sortString } from '../../../utilities';
import moment from 'moment';
import mobile from 'is-mobile';

interface Props {
    formSubmissions: FormSubmissionStore['byId'];
    stores: StoreStore['byId'];
    onViewSubmission: (submission: FormSubmission) => void;
}

const SubmissionsTable: React.FC<Props> = ({ formSubmissions, stores, onViewSubmission }) => {
    const isMobile = mobile.isMobile();
    let commentsFilter: ColumnFilterItem[] = [
        { value: '1', text: 'Has Comments' },
        { value: '0', text: 'No Comments' },
    ];
    let reviewFilter: ColumnFilterItem[] = [{ value: '1', text: 'Required' }, { value: '0', text: 'Not Required' }];
    let storesFilter: ColumnFilterItem[] = [];
    Object.values(stores).forEach((value: Store) => {
        storesFilter.push({ value: value.name, text: value.name });
    });
    const renderReview = (submission: FormSubmission) => (
        <div>
            {submission.reviewRequired && submission.reviewedOn == null && <Tag color="orange">Required</Tag>}
            {submission.reviewRequired && submission.reviewedOn != null && <Tag color="green">Reviewed</Tag>}
            {!submission.reviewRequired && <Tag>Not Required</Tag>}
        </div>
    );
    const renderComments = (submission: FormSubmission) => (
        <div>
            {submission.hasComments && <Tag color="green">Has Comments</Tag>}
            {!submission.hasComments && <Tag>No Comments</Tag>}
        </div>
    );
    const renderTools = (submission: FormSubmission) => (
        <div style={{ textAlign: 'right' }}>
            <Badge status={submission.reviewRequired && submission.reviewedOn == null ? 'warning' : undefined}>
                <Button
                    htmlType="button"
                    size="small"
                    type="primary"
                    onClick={(e: MouseEvent) => onViewSubmission(submission)}
                >
                    View Submission
                </Button>
            </Badge>
        </div>
    );
    const columns: ColumnProps<FormSubmission>[] = [
        {
            title: 'Store',
            dataIndex: 'store',
            key: 'store',
            filters: storesFilter,
            onFilter: (v, r) => r.storeId != null && stores[r.storeId] != null && stores[r.storeId].name === v,
            sorter: (a, b) =>
                sortString(nullIndexToString(a.storeId, 'name', stores), nullIndexToString(b.storeId, 'name', stores)),
            render: (t, r) => nullIndexToString(r.storeId, 'name', stores),
        },
        {
            title: 'Date Submitted',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            render: t => moment(t).format('MM/DD/YYYY - hh:mma'),
            sorter: (a, b) => moment(a.dateCreated).diff(b.dateCreated),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Comments',
            dataIndex: 'hasComments',
            key: 'hasComments',
            filters: commentsFilter,
            onFilter: (v, r) => r.hasComments == v,
            sorter: (a, b) => sortBoolean(a.hasComments, b.hasComments),
            render: (t, r) => renderComments(r),
        },
        {
            title: 'Review',
            dataIndex: 'reviewRequired',
            key: 'reviewRequired',
            filters: reviewFilter,
            onFilter: (v, r) => r.reviewRequired == v,
            sorter: (a, b) => sortBoolean(a.reviewRequired, b.reviewRequired),
            render: (t, r) => renderReview(r),
        },
        {
            title: 'Tools',
            dataIndex: 'tools',
            key: 'tools',
            render: (t, r) => renderTools(r),
        },
    ];
    if (isMobile) {
        columns.splice(2, 2);
    }
    return (
        <Card title="Form Submissions">
            <Table rowKey="id" columns={columns} dataSource={Object.values(formSubmissions)} />
        </Card>
    );
};

export default SubmissionsTable;
