import { actionCreator, getRequestAction, postRequestAction } from '../reduxTools';
import { RequestableStoreBase, RequestResponse } from '../../types';
import { abort, error, success } from 'redux-observable-requests';
import { combineReducers } from 'redux';
import { followupRequestComment } from '../../schemas';
import { GET as FORM_SUBMISSION_GET } from './formSubmission';
import { Moment } from 'moment';

//-----------------------------------------------------
// Types
//-----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FollowupRequestCommentStore extends RequestableStoreBase<FollowupRequestComment> {}

export interface FollowupRequestComment {
    id: number;
    comment: string;
    dateCreated: string | Moment;
    dateModified: string | Moment;
    userProfileId: number;
    followupRequestId: number;
}

export const GET = 'followupRequestComment/GET';
export const POST = 'followupRequestComment/POST';
export const CLEAR_LOCAL_STATE = 'followupRequestComment/CLEAR_LOCAL_STATE';

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const get = getRequestAction(GET, 'followup-request-comments', null, null, followupRequestComment);
export const post = postRequestAction<FollowupRequestComment>(POST, 'followup-request-comments');
export const clearLocalState = actionCreator(CLEAR_LOCAL_STATE);

//-----------------------------------------------------
// Epics
//-----------------------------------------------------

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const byId = (
    state: FollowupRequestCommentStore['byId'] = {},
    action: RequestResponse<any>,
): FollowupRequestCommentStore['byId'] => {
    switch (action.type) {
        case CLEAR_LOCAL_STATE:
            return {};
        case success(GET):
        case success(FORM_SUBMISSION_GET):
            if (action.payload.response.followupRequestComments != null) {
                return { ...state, ...action.payload.response.followupRequestComments };
            }
            return state;
            break;
        case success(POST):
            return { ...state, [action.payload.response.id]: action.payload.response };
        default:
            return state;
    }
};

const getLoading = (
    state: FollowupRequestCommentStore['getLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestCommentStore['getLoading'] => {
    switch (action.type) {
        case GET:
            return true;
        case abort(GET):
        case error(GET):
        case success(GET):
            return false;
        default:
            return state;
    }
};

const postLoading = (
    state: FollowupRequestCommentStore['postLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestCommentStore['postLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

const patchLoading = (
    state: FollowupRequestCommentStore['patchLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestCommentStore['patchLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

const purgeLoading = (
    state: FollowupRequestCommentStore['purgeLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestCommentStore['purgeLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

export const reducer = combineReducers<FollowupRequestCommentStore>({
    byId,
    getLoading,
    postLoading,
    patchLoading,
    purgeLoading,
});
