import {
    actionCreator,
    patchRequestAction,
    postRequestAction,
    deleteRequestAction,
    getRequestAction,
} from '../reduxTools';
import { IterableObject, RequestableStoreBase, RequestResponse } from '../../types';
import { abort, error, success } from 'redux-observable-requests';
import { combineReducers } from 'redux';
import { formSubmission } from '../../schemas';
import { Moment } from 'moment';

//-----------------------------------------------------
// Types
//-----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormSubmissionStore extends RequestableStoreBase<FormSubmission> {}

export interface FormSubmission {
    id: number;
    formId: number;
    storeId: number | null;
    dateCreated: string;
    agent: string;
    ipAddress: string;
    reviewRequired: boolean;
    reviewedOn: string | Moment | null;
    reviewedByUserProfileId: number | null;
    followupRequestId: number | null;
    hasComments: boolean;
}

export const GET = 'formSubmission/GET';
export const PATCH = 'formSubmission/PATCH';
export const POST = 'formSubmission/POST';
export const PURGE = 'formSubmission/PURGE';
export const CLEAR_LOCAL_STATE = 'formSubmission/CLEAR_LOCAL_STATE';

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const get = getRequestAction(GET, 'form-submissions', null, null, formSubmission);
export const patch = patchRequestAction<FormSubmission>(PATCH, 'form-submissions');
export const post = postRequestAction<FormSubmission>(POST, 'form-submissions');
export const purge = deleteRequestAction(PURGE, 'form-submissions');
export const clearLocalState = actionCreator(CLEAR_LOCAL_STATE);

//-----------------------------------------------------
// Epics
//-----------------------------------------------------

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const byId = (state: FormSubmissionStore['byId'] = {}, action: RequestResponse<any>): FormSubmissionStore['byId'] => {
    switch (action.type) {
        case CLEAR_LOCAL_STATE:
            return {};
        case success(GET):
            if (action.payload.response.formSubmissions != null) {
                return { ...state, ...action.payload.response.formSubmissions };
            }
            return state;
        case success(POST):
        case success(PATCH):
            return { ...state, ...{ [action.payload.response.id]: action.payload.response } };
        case success(PURGE):
            let newState: IterableObject<FormSubmission> = { ...state };
            delete newState[action.meta.id];
            return newState;
        default:
            return state;
    }
};

const getLoading = (
    state: FormSubmissionStore['getLoading'] = false,
    action: RequestResponse<any>,
): FormSubmissionStore['getLoading'] => {
    switch (action.type) {
        case GET:
            return true;
        case abort(GET):
        case error(GET):
        case success(GET):
            return false;
        default:
            return state;
    }
};

const postLoading = (
    state: FormSubmissionStore['postLoading'] = false,
    action: RequestResponse<any>,
): FormSubmissionStore['postLoading'] => {
    switch (action.type) {
        case POST:
            return true;
        case abort(POST):
        case error(POST):
        case success(POST):
            return false;
        default:
            return state;
    }
};

const patchLoading = (
    state: FormSubmissionStore['patchLoading'] = false,
    action: RequestResponse<any>,
): FormSubmissionStore['patchLoading'] => {
    switch (action.type) {
        case PATCH:
            return true;
        case abort(PATCH):
        case error(PATCH):
        case success(PATCH):
            return false;
        default:
            return state;
    }
};

const purgeLoading = (
    state: FormSubmissionStore['purgeLoading'] = false,
    action: RequestResponse<any>,
): FormSubmissionStore['purgeLoading'] => {
    switch (action.type) {
        case PURGE:
            return true;
        case abort(PURGE):
        case error(PURGE):
        case success(PURGE):
            return false;
        default:
            return state;
    }
};

export const reducer = combineReducers<FormSubmissionStore>({
    byId,
    getLoading,
    postLoading,
    patchLoading,
    purgeLoading,
});
