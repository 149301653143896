import { DataAction } from '../../../types';
import { combineReducers } from 'redux';
import { dataActionCreator } from '../../reduxTools';
// @ts-ignore
import storage from 'redux-persist/es/storage/index';
import { persistReducer } from 'redux-persist';

//-----------------------------------------------------
// Types
//-----------------------------------------------------

export interface FollowupRequestDrawerStore {
    visible: boolean;
    followupRequestId: number | null;
}

export const OPEN = 'followupRequestDrawer/OPEN';
export const SET_VISIBLE = 'followupRequestDrawer/SET_VISIBLE';
export const SET_FOLLOWUP_REQUEST_ID = 'followupRequestDrawer/SET_FOLLOWUP_REQUEST_ID';

export type OPEN = typeof OPEN;
export type SET_VISIBLE = typeof SET_VISIBLE;
export type SET_FOLLOWUP_REQUEST_ID = typeof SET_FOLLOWUP_REQUEST_ID;

export type FollowupRequestDrawerAction = SET_VISIBLE | SET_FOLLOWUP_REQUEST_ID | OPEN;

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const open = dataActionCreator<number, OPEN>(OPEN);
export const setVisible = dataActionCreator<boolean, SET_VISIBLE>(SET_VISIBLE);
export const setFollowupRequestId = dataActionCreator<number, SET_FOLLOWUP_REQUEST_ID>(SET_FOLLOWUP_REQUEST_ID);

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const visible = (
    state: FollowupRequestDrawerStore['visible'] = false,
    action: DataAction<any, FollowupRequestDrawerAction>,
): FollowupRequestDrawerStore['visible'] => {
    switch (action.type) {
        case OPEN:
            return true;
        case SET_VISIBLE:
            return action.payload;
        default:
            return state;
    }
};

const followupRequestId = (
    state: FollowupRequestDrawerStore['followupRequestId'] = null,
    action: DataAction<any, FollowupRequestDrawerAction>,
): FollowupRequestDrawerStore['followupRequestId'] => {
    switch (action.type) {
        case OPEN:
        case SET_FOLLOWUP_REQUEST_ID:
            return action.payload;
        default:
            return state;
    }
};

const followupRequestDrawerPersistConfig = {
    key: 'followupRequestDrawer',
    storage: storage,
    whitelist: ['showing', 'followupRequestId'],
};

export const reducer = persistReducer(
    followupRequestDrawerPersistConfig,
    combineReducers<FollowupRequestDrawerStore>({
        visible,
        followupRequestId,
    }),
);
