import { ActionBase, DataAction, Model } from '../types';
import { RequestAction } from 'redux-observable-requests';
import { API_URL } from '../config';
import { store } from '../';
import { Schema } from 'normalizr';

//-------------------------------------------------------------------
// Miscellaneous Utilities
//-------------------------------------------------------------------

export const actionOfType = (type: string, action: RequestAction): boolean => action.type.indexOf(type) !== -1;

export const actionModelName = (action: any): string =>
    action.type
        .split('/')
        .splice(0, 1)
        .join('');

export const apiRequestURL = (
    model: Model,
    nestedModel?: Model | null | undefined,
    modelFunction?: string | null | undefined,
    id?: number | null | undefined,
    filter?: object | null | undefined,
): string => {
    let url = API_URL + model;
    if (id != null) {
        url += `/${id}`;
    }
    if (nestedModel != null) {
        url += `/${nestedModel}`;
    }
    if (modelFunction != null) {
        url += `/${modelFunction}`;
    }
    if (filter != null) {
        url += `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }
    return url;
};

export const withAuthorizationToken = (action: RequestAction): RequestAction => {
    // @ts-ignore
    const token = store.getState().app.app.session.id;
    if (action.payload != null && action.payload.request != null && action.payload.request.headers == null) {
        action.payload.request.headers = {
            Authorization: JSON.parse(JSON.stringify(token)),
        };
    } else if (action.payload != null && action.payload.request != null) {
        action.payload.request.headers = {
            ...action.payload.request.headers,
            Authorization: JSON.parse(JSON.stringify(token)),
        };
    }
    return action;
};

//-------------------------------------------------------------------
// Action Creator HO Functions
//-------------------------------------------------------------------

export function actionCreator<A>(type: A): () => ActionBase<A> {
    return () => ({
        type: type,
    });
}

export function dataActionCreator<T, A>(type: A): (data: T) => DataAction<T, A> {
    return (data: T) => ({
        type: type,
        payload: data,
    });
}

export function getRequestAction(
    type: string,
    model: Model,
    nestedModel?: Model | null,
    modelFunction?: string | null,
    responseSchema?: Schema | null,
): (id?: number | null, filter?: object | null) => RequestAction {
    return (id?: number | null, filter?: object | null) => ({
        type: type,
        meta: {
            responseSchema,
        },
        payload: {
            request: {
                url: apiRequestURL(model, nestedModel, modelFunction, id, filter),
                method: 'GET',
            },
        },
    });
}

export function postRequestAction<D>(
    type: string,
    model: Model,
    nestedModel?: Model | null,
    modelFunction?: string,
): (data: D) => RequestAction {
    return (data: D) => ({
        type: type,
        payload: {
            request: {
                url: apiRequestURL(model, nestedModel, modelFunction),
                method: 'POST',
                body: data,
            },
        },
    });
}

export function patchRequestAction<D>(
    type: string,
    model: Model,
    nestedModel?: Model | null,
    modelFunction?: string,
): (id: number, data: D) => RequestAction {
    return (id: number, data: D) => ({
        type: type,
        payload: {
            request: {
                url: apiRequestURL(model, nestedModel, modelFunction, id),
                method: 'PATCH',
                body: data,
            },
        },
    });
}

export function deleteRequestAction(
    type: string,
    model: Model,
    nestedModel?: Model | null,
    modelFunction?: string,
): (id: number) => RequestAction {
    return (id: number) => ({
        type: type,
        payload: {
            request: {
                url: apiRequestURL(model, nestedModel, modelFunction, id),
                method: 'DELETE',
            },
        },
    });
}
