import React, { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './Login';

const LoginRoute: React.FC = ({}): ReactElement => {
    return (
        <Switch>
            <Route path="/" component={Login} />
        </Switch>
    );
};

export default LoginRoute;
