import { schema } from 'normalizr';
const userProfile = new schema.Entity('userProfiles');
const store = new schema.Entity('stores');
const form = new schema.Entity('forms');
const formField = new schema.Entity('formFields');
const formFieldValue = new schema.Entity('formFieldValues', {}, { idAttribute: 'uuid' });
const formSubmission = new schema.Entity('formSubmissions');
const followupRequest = new schema.Entity('followupRequests');
const followupRequestComment = new schema.Entity('followupRequestComments');
const followupRequestStatus = new schema.Entity('followupRequestStatuses');
userProfile.define({});
store.define({});
formField.define({
    form: form,
    formFieldValues: [formFieldValue],
});
formFieldValue.define({});
formSubmission.define({
    form: form,
    formFieldValues: [formFieldValue],
    followupRequest: followupRequest,
    reviewedByUserProfile: userProfile,
});
form.define({
    formFields: [formField],
    formSubmissions: [formSubmission],
});
followupRequest.define({
    formSubmission: formSubmission,
    acknowledgingUserProfile: userProfile,
    followupRequestComments: [followupRequestComment],
    followupRequestStatus: followupRequestStatus,
    store: store,
});
followupRequestComment.define({
    userProfile: userProfile,
    followupRequest: followupRequest,
});
followupRequestStatus.define({});
export {
    userProfile,
    store,
    formField,
    formFieldValue,
    formSubmission,
    form,
    followupRequest,
    followupRequestComment,
    followupRequestStatus,
};
