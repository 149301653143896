import React from 'react';
import { Link, NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { Breadcrumb, Icon, Layout, Menu } from 'antd';
import './AppNavigation.css';
import UserMenu from './UserMenu';
interface Props {
    children: any;
}

const AppNavigation: React.FC<Props & RouteComponentProps> = ({ children, location }): React.ReactElement => {
    const generateBreadcrumbs = (pathname: string): React.ReactNode => {
        pathname = pathname.slice(1);
        if (pathname.substr(-1) === '/') {
            pathname = pathname.slice(0, -1);
        }
        let crumbs = pathname === '' ? ['dashboard'] : pathname.split('/');
        let link = '';
        return crumbs.map((v, k) => {
            link = pathname === '' ? '' : link + '/' + v;
            return (
                <Breadcrumb.Item key={v}>
                    <Link to={link}>{v.toLowerCase()}</Link>
                </Breadcrumb.Item>
            );
        });
    };

    const generateSelectedKeys = (pathname: string): string[] => {
        let keys: string[] = [];
        let routes = pathname.split('/');
        let previousRoute = '';
        routes.forEach((route, key) => {
            const delimiter = key === 0 ? '' : '_';
            keys.push(previousRoute + route);
            previousRoute += route + delimiter;
        });
        keys.splice(0, 1);
        return keys;
    };

    const BreadcrumbItems = generateBreadcrumbs(location.pathname);
    const selectedKeys = generateSelectedKeys(location.pathname);

    /*
     Saved for later...
     <Menu.Item key={'dashboard'}>
     <NavLink to="/dashboard">
     <Icon type="bar-chart" />
     <span className="menu-item-text">Dashboard</span>
     </NavLink>
     </Menu.Item>
     */

    return (
        <Layout>
            <Layout.Header className="header flex justify-space-between">
                <h1 id="wms-logo">CRM</h1>
                <UserMenu />
            </Layout.Header>
            <Layout>
                <Layout.Sider id="app-navigation-sidebar" breakpoint="lg" width={200}>
                    <Menu mode="inline" id="app-navigation-menu" selectedKeys={selectedKeys}>
                        <Menu.Item key={'submissions'}>
                            <NavLink to="/submissions">
                                <Icon type="form" />
                                <span className="menu-item-text">Submissions</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={'followup-requests'}>
                            <NavLink to="/followup-requests">
                                <Icon type="customer-service" />
                                <span className="menu-item-text">Followup Requests</span>
                            </NavLink>
                        </Menu.Item>
                    </Menu>
                </Layout.Sider>
                <Layout id="app-content-root">
                    <Breadcrumb>{BreadcrumbItems}</Breadcrumb>
                    <Layout.Content id="app-content">{children}</Layout.Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default withRouter(AppNavigation);
