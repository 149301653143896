import { actionCreator, getRequestAction } from '../reduxTools';
import { RequestableStoreBase, RequestResponse } from '../../types';
import { abort, error, success } from 'redux-observable-requests';
import { combineReducers } from 'redux';
import { formFieldValue } from '../../schemas';
import { GET as FORM_SUBMISSION_GET } from './formSubmission';
import { store } from '../../index';
import { formatPhoneNumber, nullToString } from '../../utilities';
import moment from 'moment';

//-----------------------------------------------------
// Types
//-----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormFieldValueStore extends RequestableStoreBase<FormFieldValue> {}

export interface FormFieldValue {
    formSubmissionId: number;
    formFieldId: number;
    formFieldValueType: string;
    formFieldParseAs: string;
    value: any;
    uuid: string;
}

export const GET = 'formFieldValue/GET';
export const CLEAR_LOCAL_STATE = 'formFieldValue/CLEAR_LOCAL_STATE';

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const get = getRequestAction(GET, 'form-field-values', null, null, formFieldValue);
export const clearLocalState = actionCreator(CLEAR_LOCAL_STATE);

//-----------------------------------------------------
// Epics
//-----------------------------------------------------

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const byId = (state: FormFieldValueStore['byId'] = {}, action: RequestResponse<any>): FormFieldValueStore['byId'] => {
    switch (action.type) {
        case CLEAR_LOCAL_STATE:
            return {};
        case success(GET):
        case success(FORM_SUBMISSION_GET):
            if (action.payload.response.formFieldValues != null) {
                return { ...state, ...action.payload.response.formFieldValues };
            }
            return state;
        default:
            return state;
    }
};

const getLoading = (
    state: FormFieldValueStore['getLoading'] = false,
    action: RequestResponse<any>,
): FormFieldValueStore['getLoading'] => {
    switch (action.type) {
        case GET:
            return true;
        case abort(GET):
        case error(GET):
        case success(GET):
            return false;
        default:
            return state;
    }
};

const postLoading = (
    state: FormFieldValueStore['postLoading'] = false,
    action: RequestResponse<any>,
): FormFieldValueStore['postLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

const patchLoading = (
    state: FormFieldValueStore['patchLoading'] = false,
    action: RequestResponse<any>,
): FormFieldValueStore['patchLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

const purgeLoading = (
    state: FormFieldValueStore['purgeLoading'] = false,
    action: RequestResponse<any>,
): FormFieldValueStore['purgeLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

//-----------------------------------------------------
// Utility Functions
//-----------------------------------------------------

const makeBooleanHumanReadable = (value: boolean) => {
    return value ? 'Yes' : 'No';
};

const makeStoreIdHumanReadable = (value: number) => {
    let storeName = 'Unknown';
    try {
        let storeO = store.getState().store.byId[value];
        storeName = storeO.name;
    } catch (e) {
        return storeName;
    }
    return storeName;
};

export const getHumanReadableValue = (value: FormFieldValue) => {
    switch (value.formFieldParseAs) {
        case 'storeId':
            return makeStoreIdHumanReadable(value.value);
        case 'dateOfVisit':
            return moment(value.value).format('MM/DD/YYYY');
        case 'requesterPhoneNumber':
            return formatPhoneNumber(value.value);
    }
    switch (value.formFieldValueType) {
        case 'boolean':
            return makeBooleanHumanReadable(value.value);
        case 'dateTime':
            return moment(value.value).format('MM/DD/YYYY - hh:mma');
    }
    return nullToString(value.value);
};

export const reducer = combineReducers<FormFieldValueStore>({
    byId,
    getLoading,
    postLoading,
    patchLoading,
    purgeLoading,
});
