import * as app from './app';
import * as followupRequestDrawer from './followupRequestDrawer';
import { combineReducers } from 'redux';

const reducer = combineReducers({
    app: app.reducer,
    followupRequestDrawer: followupRequestDrawer.reducer,
});

export interface AppStore {
    app: app.AppStore;
    followupRequestDrawer: followupRequestDrawer.FollowupRequestDrawerStore;
}

export default reducer;
export { app, followupRequestDrawer, reducer };
