import React, { useEffect, useState } from 'react';
import { app, CRMStore, form, formSubmission, store } from '../../store/modules';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormStore } from '../../store/modules/form';
import { Select, DatePicker, Row, Col, Spin, Empty } from 'antd';
import { FormSubmission, FormSubmissionStore } from '../../store/modules/formSubmission';
import SubmissionStatisticsHeading from './components/SubmissionStatisticsHeading';
import { FormFieldStore } from '../../store/modules/formField';
import { FormFieldValueStore } from '../../store/modules/formFieldValue';
import { StoreStore } from '../../store/modules/store';
import { createdWithinDateRange } from '../../store/selectors';
import SubmissionsTable from './components/SubmissionsTable';
import FormSubmissionDrawer from './components/FormSubmissionDrawer';
import { AppStore } from '../../store/modules/app/app';
import { RouteComponentProps, withRouter } from 'react-router';
import mobile from 'is-mobile';

interface MappedDispatchProps {
    setDateRange: typeof app.app.setDateRange;
    getSubmissions: typeof formSubmission.get;
    getForms: typeof form.get;
    getStores: typeof store.get;
    setFormId: typeof app.app.setFormId;
}

const mapDispatchToProps: MappedDispatchProps = {
    setDateRange: app.app.setDateRange,
    getSubmissions: formSubmission.get,
    getForms: form.get,
    getStores: store.get,
    setFormId: app.app.setFormId,
};

interface MappedStateProps {
    forms: FormStore['byId'];
    formSubmissions: FormSubmissionStore['byId'];
    formFields: FormFieldStore['byId'];
    formFieldValues: FormFieldValueStore['byId'];
    formId: AppStore['formId'];
    dateRange: AppStore['dateRange'];
    stores: StoreStore['byId'];
}

const mapStateToProps = (state: CRMStore): MappedStateProps => ({
    forms: state.form.byId,
    formSubmissions: state.formSubmission.byId,
    formFields: state.formField.byId,
    formFieldValues: state.formFieldValue.byId,
    formId: state.app.app.formId,
    dateRange: state.app.app.dateRange,
    stores: state.store.byId,
});

interface Match {
    id: string | undefined;
}

type Props = MappedDispatchProps & MappedStateProps & RouteComponentProps<Match>;

const Submissions: React.FC<Props> = ({
    getForms,
    forms,
    getSubmissions,
    formSubmissions,
    dateRange,
    setDateRange,
    getStores,
    stores,
    match,
    history,
    formId,
    setFormId,
}): React.ReactElement => {
    const isMobile = mobile.isMobile();
    const filtersTextAlign = isMobile ? 'left' : 'right';
    const [loading, setLoading] = useState<boolean>(false);
    const pageTitle = formId != null ? `Submissions for ${forms[formId].name}` : '';
    const formSubmissionId = match.params.id != null ? parseInt(match.params.id, 10) : null;
    let formSubmissionsWithinDateRange = createdWithinDateRange<FormSubmission>(
        formSubmissions,
        dateRange[0],
        dateRange[1],
    );
    Object.keys(formSubmissionsWithinDateRange).forEach((key: string) => {
        const id = parseInt(key, 10);
        if (formSubmissionsWithinDateRange[id].formId !== formId) {
            delete formSubmissionsWithinDateRange[id];
        }
    });
    const handleViewSubmission = (submission: FormSubmission) => {
        history.push(`/submissions/${submission.id}`);
    };
    const handleCloseSubmission = () => {
        history.push('/submissions');
    };
    const hydrate = (silent: boolean) => {
        if (!silent) {
            setLoading(true);
        }
        getSubmissions(null, {
            where: {
                and: [
                    {
                        dateCreated: { lt: dateRange[1] },
                    },
                    {
                        dateCreated: { gt: dateRange[0] },
                    },
                    { formId: formId },
                ],
            },
        });
    };
    useEffect(() => {
        getForms();
        getStores();
        const updateInterval = setInterval(() => {
            hydrate(true);
        }, 1000 * 15);
        return () => {
            clearInterval(updateInterval);
        };
    }, []);
    useEffect(() => {
        if (formId == null) {
            return;
        }
        hydrate(false);
    }, [formId, dateRange]);
    useEffect(() => {
        setLoading(false);
    }, [formSubmissions]);
    const pageContent = (
        <div>
            <div style={{ marginTop: 16 }}>
                <SubmissionStatisticsHeading formSubmissions={formSubmissionsWithinDateRange} dateRange={dateRange} />
            </div>
            <div style={{ marginTop: 16 }}>
                <SubmissionsTable
                    formSubmissions={formSubmissionsWithinDateRange}
                    stores={stores}
                    onViewSubmission={handleViewSubmission}
                />
            </div>
        </div>
    );
    return (
        <div>
            <div>
                <Row>
                    <Col xs={24} md={12}>
                        {pageTitle != '' && dateRange[0] != null && dateRange[1] != null && (
                            <div>
                                <h2 style={{ marginTop: 0, marginBottom: 0 }}>{pageTitle}</h2>
                                <span>
                                    Showing submissions between {dateRange[0].format('MM/DD/YYYY')} {' - '}
                                    {dateRange[1].format('MM/DD/YYYY')}
                                </span>
                            </div>
                        )}
                    </Col>
                    <Col xs={24} md={12} style={{ textAlign: filtersTextAlign }}>
                        <Select
                            onChange={v => setFormId(parseInt(v + '', 10))}
                            value={formId}
                            style={{ minWidth: 200, marginRight: 8 }}
                            placeholder="Select Form"
                        >
                            {Object.values(forms).map(form => (
                                <Select.Option key={form.id} value={form.id}>
                                    {form.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <DatePicker.RangePicker
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={v => setDateRange(v)}
                            value={[moment(dateRange[0]), moment(dateRange[1])]}
                            allowClear={false}
                        />
                    </Col>
                </Row>
                {pageTitle != '' && dateRange[0] != null && dateRange[1] != null && (
                    <div>
                        {loading && <Spin tip="Fetching data...">{pageContent}</Spin>}
                        {!loading && pageContent}
                    </div>
                )}
                {pageTitle == '' && (
                    <div style={{ marginTop: 16 }}>
                        <Empty description="Select a form to view metrics." />
                    </div>
                )}
            </div>
            <FormSubmissionDrawer
                formSubmissionId={formSubmissionId}
                visible={formSubmissionId != null}
                onClose={handleCloseSubmission}
            />
        </div>
    );
};

export default connect<MappedStateProps, MappedDispatchProps, {}, CRMStore>(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Submissions));
