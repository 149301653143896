import { actionCreator, apiRequestURL, getRequestAction, patchRequestAction } from '../reduxTools';
import {
    EventListenerArgs,
    EventListenerCallback,
    EventListenerCallbackList,
    RequestableStoreBase,
    RequestResponse,
} from '../../types';
import { abort, error, success } from 'redux-observable-requests';
import { combineReducers } from 'redux';
import { followupRequest } from '../../schemas';
import { GET as FORM_SUBMISSION_GET } from './formSubmission';
import { Moment } from 'moment';
import { store } from '../../index';

//-----------------------------------------------------
// Types
//-----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FollowupRequestStore extends RequestableStoreBase<FollowupRequest> {}

export interface FollowupRequest {
    id: number;
    formSubmissionId: number;
    followupRequestStatusId: number;
    dateCreated: string | Moment;
    dateModified: string | Moment | null;
    requesterFirstName: string | null;
    requesterLastName: string | null;
    requesterPhoneNumber: string | null;
    requesterEmailAddress: string | null;
    storeId: number | null;
}

export const GET = 'followupRequest/GET';
export const PATCH = 'followupRequest/PATCH';
export const CLEAR_LOCAL_STATE = 'followupRequest/CLEAR_LOCAL_STATE';

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const get = getRequestAction(GET, 'followup-requests', null, null, followupRequest);
export const patch = patchRequestAction(PATCH, 'followup-requests');
export const clearLocalState = actionCreator(CLEAR_LOCAL_STATE);

//-----------------------------------------------------
// Epics
//-----------------------------------------------------

//-----------------------------------------------------
// Event Listener
//-----------------------------------------------------

// The below functions, methods and Types are not yet working. Do not use.
// They are merely saved for later (if needed).
const eventListeners: EventListenerCallbackList<FollowupRequest> = {};
var changeStreamEventSource: EventSource | null = null;
const callEventListeners = (args: EventListenerArgs<FollowupRequest>) => {
    Object.values(eventListeners).forEach(cb => {
        cb(args);
    });
};
export const registerEventListener = (cb: EventListenerCallback<FollowupRequest>): number => {
    const id = Math.random();
    eventListeners[id] = cb;

    if (changeStreamEventSource == null) {
        const token = store.getState().app.app.session.id;
        const changeStreamURL =
            apiRequestURL('followup-requests', null, 'change-stream') + '?_format=event-stream&access_token=' + token;
        changeStreamEventSource = new EventSource(changeStreamURL);
        changeStreamEventSource.addEventListener('data', msg => {
            // @ts-ignore
            callEventListeners(JSON.parse(msg.data));
        });
    }
    return id;
};
export const removeEventListener = (id: number): void => {
    delete eventListeners[id];
};

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const byId = (state: FollowupRequestStore['byId'] = {}, action: RequestResponse<any>): FollowupRequestStore['byId'] => {
    switch (action.type) {
        case CLEAR_LOCAL_STATE:
            return {};
        case success(GET):
        case success(FORM_SUBMISSION_GET):
            if (action.payload.response.followupRequests != null) {
                return { ...state, ...action.payload.response.followupRequests };
            }
            return state;
        case success(PATCH):
            return { ...state, ...{ [action.payload.response.id]: action.payload.response } };
        default:
            return state;
    }
};

const getLoading = (
    state: FollowupRequestStore['getLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestStore['getLoading'] => {
    switch (action.type) {
        case GET:
            return true;
        case abort(GET):
        case error(GET):
        case success(GET):
            return false;
        default:
            return state;
    }
};

const postLoading = (
    state: FollowupRequestStore['postLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestStore['postLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

const patchLoading = (
    state: FollowupRequestStore['patchLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestStore['patchLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

const purgeLoading = (
    state: FollowupRequestStore['purgeLoading'] = false,
    action: RequestResponse<any>,
): FollowupRequestStore['purgeLoading'] => {
    switch (action.type) {
        default:
            return state;
    }
};

export const reducer = combineReducers<FollowupRequestStore>({
    byId,
    getLoading,
    postLoading,
    patchLoading,
    purgeLoading,
});
