import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Modal } from 'antd';
import { app, CRMStore } from '../store/';
import { AppStore } from '../store/modules/app/app';

const mapStateToProps = (state: CRMStore): StateProps => ({
    pendingNotifications: state.app.app.pendingNotifications,
});

const mapDispatchToProps: DispatchProps = {
    purgeAllNotifications: app.app.purgeAllNotifications,
};

interface StateProps {
    pendingNotifications: AppStore['pendingNotifications'];
}

interface DispatchProps {
    purgeAllNotifications: typeof app.app.purgeAllNotifications;
}

interface Notification {
    type: string;
    message: string;
    details: string;
}

type Props = StateProps & DispatchProps;

const openModal = (type: string, title: string, message: string, details: string): void => {
    // @ts-ignore
    Modal[type]({
        title,
        content: (
            <div>
                <p>{message}</p>
                <pre className={'error'} style={{ maxHeight: 400, overflow: 'auto' }}>
                    {details}
                </pre>
            </div>
        ),
    });
};

const openNotification = (no: Notification): void => {
    if (no.type === 'critical') {
        openModal(
            'error',
            'We ran into an issue...',
            "We've ran into an issue processing your request. You may try refreshing this page, or trying again later. Error details can be seen below.",
            no.details,
        );
    } else if (no.type === 'permission') {
        openModal(
            'warn',
            'Sorry, your request was denied...',
            'It looks like your request was denied due to lack of permissions. If you believe this is in error, please submit a ticket to the IT department. Important information about the request is listed below.',
            no.details,
        );
    } else {
        // @ts-ignore
        message[no.type](no.message);
    }
};

class AppNotificationDrawer extends Component<Props> {
    public componentDidUpdate = (prevProps: any, prevState: any, snapshot: any): void => {
        const { purgeAllNotifications, pendingNotifications } = this.props;
        pendingNotifications.forEach(notification => {
            openNotification(notification);
        });
        if (pendingNotifications.length > 0) {
            purgeAllNotifications();
        }
    };
    public render(): React.ReactNode {
        return null;
    }
}

export default connect<StateProps, DispatchProps, {}, CRMStore>(
    mapStateToProps,
    mapDispatchToProps,
)(AppNotificationDrawer);
