import {
    actionCreator,
    patchRequestAction,
    postRequestAction,
    deleteRequestAction,
    getRequestAction,
} from '../reduxTools';
import { IterableObject, RequestableStoreBase, RequestResponse } from '../../types';
import { abort, error, success } from 'redux-observable-requests';
import { combineReducers } from 'redux';
import { form } from '../../schemas';
import { GET as FORM_SUBMISSION_GET } from './formSubmission';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { setFormId } from './app/app';
import { store } from '../../index';
import { of } from 'rxjs/internal/observable/of';
//-----------------------------------------------------
// Types
//-----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormStore extends RequestableStoreBase<Form> {}

export interface Form {
    id?: number;
    name: string;
    address: string;
    type: string;
}

export const GET = 'form/GET';
export const PATCH = 'form/PATCH';
export const POST = 'form/POST';
export const PURGE = 'form/PURGE';
export const CLEAR_LOCAL_STATE = 'form/CLEAR_LOCAL_STATE';

//-----------------------------------------------------
// Action Creators
//-----------------------------------------------------

export const get = getRequestAction(GET, 'forms', null, null, form);
export const patch = patchRequestAction<Form>(PATCH, 'forms');
export const post = postRequestAction<Form>(POST, 'forms');
export const purge = deleteRequestAction(PURGE, 'forms');
export const clearLocalState = actionCreator(CLEAR_LOCAL_STATE);

//-----------------------------------------------------
// Epics
//-----------------------------------------------------

const selectInitialForm: Epic = (action$): Observable<any> =>
    action$.pipe(
        ofType(success(GET)),
        flatMap(action => {
            if (
                store.getState().app.app.formId == null &&
                action.payload.response.forms != null &&
                Object.keys(action.payload.response.forms).length > 0
            ) {
                return of(setFormId(action.payload.response.forms[Object.keys(action.payload.response.forms)[0]].id));
            }
            console.log(action.payload.response.forms);
            return EMPTY;
        }),
    );

export const epic = combineEpics(selectInitialForm);

//-----------------------------------------------------
// Reducers
//-----------------------------------------------------

const byId = (state: FormStore['byId'] = {}, action: RequestResponse<any>): FormStore['byId'] => {
    switch (action.type) {
        case CLEAR_LOCAL_STATE:
            return {};
        case success(GET):
        case success(FORM_SUBMISSION_GET):
            if (action.payload.response.forms != null) {
                return { ...state, ...action.payload.response.forms };
            }
            return state;
        case success(POST):
        case success(PATCH):
            return { ...state, ...{ [action.payload.response.id]: action.payload.response } };
        case success(PURGE):
            let newState: IterableObject<Form> = { ...state };
            delete newState[action.meta.id];
            return newState;
        default:
            return state;
    }
};

const getLoading = (state: FormStore['getLoading'] = false, action: RequestResponse<any>): FormStore['getLoading'] => {
    switch (action.type) {
        case GET:
            return true;
        case abort(GET):
        case error(GET):
        case success(GET):
            return false;
        default:
            return state;
    }
};

const postLoading = (
    state: FormStore['postLoading'] = false,
    action: RequestResponse<any>,
): FormStore['postLoading'] => {
    switch (action.type) {
        case POST:
            return true;
        case abort(POST):
        case error(POST):
        case success(POST):
            return false;
        default:
            return state;
    }
};

const patchLoading = (
    state: FormStore['patchLoading'] = false,
    action: RequestResponse<any>,
): FormStore['patchLoading'] => {
    switch (action.type) {
        case PATCH:
            return true;
        case abort(PATCH):
        case error(PATCH):
        case success(PATCH):
            return false;
        default:
            return state;
    }
};

const purgeLoading = (
    state: FormStore['purgeLoading'] = false,
    action: RequestResponse<any>,
): FormStore['purgeLoading'] => {
    switch (action.type) {
        case PURGE:
            return true;
        case abort(PURGE):
        case error(PURGE):
        case success(PURGE):
            return false;
        default:
            return state;
    }
};

export const reducer = combineReducers<FormStore>({
    byId,
    getLoading,
    postLoading,
    patchLoading,
    purgeLoading,
});
